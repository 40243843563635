import * as React from 'react';
import { cn } from '~/utils';
import './css/Tab.css';

interface TabProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean;
  children?: React.ReactNode;
  urlValue?: string; // Custom URL parameter value
}

const Tab = React.forwardRef<HTMLButtonElement, TabProps>(
  ({ className, isSelected = false, children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          // base styles
          'flex items-center justify-center gap-2.5 px-1 py-2 text-center font-sans text-[16px] leading-[24px] tracking-[0.32px] text-[#6A6A6A] md:text-[20px] dark:text-[#878FA4]',
          // selected styles
          isSelected === true &&
            'tab-selected-bg tab-selected-border text-[#0F172A] dark:text-white',
          className,
        )}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Tab.displayName = 'Tab';

interface TabGroupProps {
  defaultIndex?: number;
  index?: number; // New prop for controlled mode
  onChange?: (index: number) => void;
  children: React.ReactNode;
  className?: string;
}

// Props we add to each Tab child
interface TabChildProps {
  isSelected: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TabGroup: React.FC<TabGroupProps> = ({
  defaultIndex = 0,
  index, // Controlled mode if provided
  onChange,
  children,
  className,
}) => {
  const tabValues = React.useMemo(() => {
    const values: string[] = [];
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child) && child.type === Tab) {
        // Use the urlValue prop if provided, otherwise use the default behavior
        if (child.props.urlValue) {
          values.push(child.props.urlValue);
        } else {
          values.push(
            typeof child.props.children === 'string'
              ? child.props.children.toLowerCase().replace(/\s+/g, '-')
              : `tab-${values.length}`,
          );
        }
      }
    });
    return values;
  }, [children]);

  const initFromUrl = React.useCallback(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const urlValue = params.get('tab');
      if (urlValue !== null && urlValue !== '') {
        // First try exact match (original behavior)
        let index = tabValues.findIndex(
          (value) => value.toLowerCase() === urlValue.toLowerCase(),
        );

        // If no exact match, try more flexible matching
        if (index < 0) {
          // Try to find a tab value that contains the URL value or vice versa
          index = tabValues.findIndex((value) => {
            const simplifiedValue = value
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '');
            const simplifiedUrlValue = urlValue
              .toLowerCase()
              .replace(/[^a-z0-9]/g, '');

            return (
              // Check if URL value is a substring of tab value
              value.toLowerCase().includes(urlValue.toLowerCase()) ||
              // Check if tab value is a substring of URL value
              urlValue.toLowerCase().includes(value.toLowerCase()) ||
              // Check simplified versions (removing special chars)
              simplifiedValue.includes(simplifiedUrlValue) ||
              simplifiedUrlValue.includes(simplifiedValue)
            );
          });
        }

        return index >= 0 ? index : defaultIndex;
      }
    }
    return defaultIndex;
  }, [defaultIndex, tabValues]);

  // Use internal state only in uncontrolled mode
  const [internalSelectedIndex, setInternalSelectedIndex] =
    React.useState<number>(() => initFromUrl());

  // Use either the controlled index or internal state
  const selectedIndex = index !== undefined ? index : internalSelectedIndex;

  // Notify parent component about URL-based tab selection on mount
  React.useEffect(() => {
    // If in controlled mode and we have an onChange handler
    if (index === undefined && onChange) {
      const initialIndex = initFromUrl();
      if (initialIndex !== defaultIndex) {
        onChange(initialIndex);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const value = tabValues[selectedIndex];

      if (value !== undefined && value !== '') {
        const params = new URLSearchParams(window.location.search);
        params.set('tab', value);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }
    }
  }, [selectedIndex, tabValues]);

  const handleTabSelect = (newIndex: number) => {
    // In uncontrolled mode, update internal state
    if (index === undefined) {
      setInternalSelectedIndex(newIndex);
    }

    // Always call onChange if provided
    if (onChange !== undefined) {
      onChange(newIndex);
    }
  };

  return (
    <div className={cn('flex flex-col', className)}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const newProps: TabChildProps = {
            isSelected: index === selectedIndex,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              handleTabSelect(index);
              if (child.props.onClick !== undefined) {
                child.props.onClick(e);
              }
            },
          };
          return React.cloneElement(child, newProps);
        }
        return child;
      })}
    </div>
  );
};

export { Tab, TabGroup };
