import { EModelEndpoint } from 'librechat-data-provider';
import { useGetEndpointsQuery } from 'librechat-data-provider/react-query';
import type { ReactNode } from 'react';
import { useChatContext, useAgentsMapContext, useAssistantsMapContext } from '~/Providers';
import ConvoIcon from '~/components/Endpoints/ConvoIcon';
import { getIconEndpoint, cn } from '~/utils';
import { useSubmitMessage } from '~/hooks';
import ConvoStarter from './LandingPage/ConvoStarter';
import { getConvoPrompts } from './LandingPage/ConvoPrompts';
import { getModelSpecDescriptions } from './LandingPage/ModelSpecDescriptions';

export default function Landing({ Header }: { Header?: ReactNode }) {
  const { conversation } = useChatContext();
  const agentsMap = useAgentsMapContext();
  const assistantMap = useAssistantsMapContext();
  const { data: endpointsConfig } = useGetEndpointsQuery();

  let { endpoint = '' } = conversation ?? {};

  if (
    endpoint === EModelEndpoint.chatGPTBrowser ||
    endpoint === EModelEndpoint.azureOpenAI ||
    endpoint === EModelEndpoint.gptPlugins
  ) {
    endpoint = EModelEndpoint.openAI;
  }

  const iconURL = conversation?.iconURL;
  endpoint = getIconEndpoint({ endpointsConfig, iconURL, endpoint });

  // const { entity, isAgent } = getEntity({
  //   endpoint,
  //   agentsMap,
  //   assistantMap,
  //   agent_id: conversation?.agent_id,
  //   assistant_id: conversation?.assistant_id,
  // });

  const convo = getConvoPrompts(conversation?.modelLabel);

  const modelSpecs = getModelSpecDescriptions(conversation?.modelLabel);

  const containerClassName =
    'relative flex h-full items-center justify-center rounded-full bg-white text-[#0F172A]';

  const { submitMessage } = useSubmitMessage();
  const sendConversationStarter = (text: string) => submitMessage({ text });

  const isGptPlugins = conversation?.endpoint === EModelEndpoint.gptPlugins;

  return (
    <div className='relative h-full px-6 text-text-primary'>
      <div className='absolute left-0 right-0'>{Header != null ? Header : null}</div>
      <div className='flex h-full items-center justify-center'>
        <div className='mx-auto max-h-[calc(100vh-240px)] max-w-[354px] overflow-y-auto py-8 md:max-w-[730px] lg:max-w-[752px]'>
          <div className='flex flex-col items-center'>
            <div className={cn('relative mb-3 h-8 w-8 md:mb-6 md:h-12 md:w-12')}>
              <ConvoIcon
                agentsMap={agentsMap}
                assistantMap={assistantMap}
                conversation={conversation}
                endpointsConfig={endpointsConfig}
                containerClassName={containerClassName}
                context='landing'
              />
            </div>
            <div className={cn('w-full text-center', isGptPlugins ? 'mb-8' : 'mb-0')}>
              <h2>
                <div className='mx-auto mb-6 max-w-[350px] text-[14px] md:hidden'>
                  {modelSpecs[0]?.mobileDescription}
                </div>
                <div className='mb-8 hidden text-[18px] md:block md:max-w-[752px]'>
                  {modelSpecs[0]?.desktopDescription}
                </div>
              </h2>
            </div>
            <div className='grid w-full grid-cols-1 gap-2 md:px-4 lg:grid-cols-2 lg:gap-4'>
              {convo.length > 0 &&
                convo.map(({ text, emoji }, index) => (
                  <ConvoStarter
                    key={index}
                    text={text}
                    onClick={() => sendConversationStarter(text)}
                    emoji={emoji}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
