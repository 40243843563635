import * as React from 'react';
import { ArrowDownRight } from 'lucide-react';
import { cn } from '~/utils';
import { logger } from '~/utils';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'ref'> {
  variant?: 'default' | 'big';
  onArrowClick?: () => void;
  initialExpanded?: boolean;
  error?: boolean;
  errorMessage?: string;
}

type TextareaProps = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'ref'
>;

// Style variants
const variants = {
  default: {
    container: 'relative w-full',
    input:
      'flex w-full rounded-lg border border-[#6A6A6A] dark:border-[#273558] px-4 py-3 text-[16px] text-[#6A6A6A] dark:text-white ring-offset-background placeholder:text-muted-foreground placeholder:leading-[16px] leading-[16px] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 bg-[#F2F2F2] dark:bg-[#1C2846] placeholder:text-[#6A6A6A] dark:placeholder:text-white',
    textarea: {
      collapsed:
        'w-full rounded-lg border border-[#6A6A6A] dark:border-[#273558] px-4 py-3 text-[16px] text-[#6A6A6A] dark:text-white ring-offset-background placeholder:text-muted-foreground placeholder:leading-[16px] leading-[16px] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 bg-[#F2F2F2] dark:bg-[#1C2846] placeholder:text-[#6A6A6A] dark:placeholder:text-white h-[120px] resize-none align-top placeholder:text-left placeholder:align-top overflow-y-auto transition-all duration-300 ease-in-out',
      expanded:
        'w-full rounded-lg border border-[#6A6A6A] dark:border-[#273558] px-4 py-3 text-[16px] text-[#6A6A6A] dark:text-white ring-offset-background placeholder:text-muted-foreground placeholder:leading-[16px] leading-[16px] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 bg-[#F2F2F2] dark:bg-[#1C2846] placeholder:text-[#6A6A6A] dark:placeholder:text-white h-[240px] resize-none align-top placeholder:text-left placeholder:align-top overflow-y-auto transition-all duration-300 ease-in-out',
    },
    arrowButton:
      'absolute bottom-2 right-2 cursor-pointer text-[#6A6A6A] dark:text-white transition-transform duration-300 ease-in-out',
  },
};

const Input = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps
>(
  (
    {
      className,
      placeholder,
      variant = 'default',
      onArrowClick,
      initialExpanded = false,
      error,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const [isExpanded, setIsExpanded] = React.useState(initialExpanded);
    const errorId = React.useId();

    const handleArrowClick = () => {
      logger.log('Input', 'Arrow clicked');
      setIsExpanded(!isExpanded);
      if (onArrowClick) {
        onArrowClick();
      }
    };

    if (variant === 'big') {
      return (
        <div className={variants.default.container}>
          <textarea
            className={cn(
              isExpanded
                ? variants.default.textarea.expanded
                : variants.default.textarea.collapsed,
              error && 'border-red-500 dark:border-red-400',
              className ?? '',
            )}
            placeholder={placeholder}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={error && errorMessage ? errorId : undefined}
            {...(props as unknown as TextareaProps)}
          />
          <button
            type="button"
            className={cn(
              variants.default.arrowButton,
              isExpanded ? 'rotate-180' : '',
            )}
            onClick={handleArrowClick}
            aria-label={isExpanded ? 'Collapse input' : 'Expand input'}
          >
            <ArrowDownRight />
          </button>
          {error && errorMessage && (
            <div
              id={errorId}
              role="alert"
              className="mt-1 text-sm text-red-500 dark:text-red-400"
            >
              Error, {errorMessage}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={variants.default.container}>
        <input
          className={cn(
            variants.default.input, 
            error && 'border-red-500 dark:border-red-400',
            className ?? '',
          )}
          placeholder={placeholder}
          ref={ref as React.ForwardedRef<HTMLInputElement>}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={error && errorMessage ? errorId : undefined}
          {...props}
        />
        {error && errorMessage && (
          <div
            id={errorId}
            role="alert"
            className="mt-1 text-sm text-red-500 dark:text-red-400"
          >
            Error, {errorMessage}
          </div>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
