import { useState, useEffect } from 'react';
import logger from '~/utils/logger';

/**
 * A custom hook that persists state in localStorage
 * @param key The key to use for localStorage
 * @param initialValue The initial value to use if no value is found in localStorage
 * @returns A stateful value and a function to update it, similar to useState
 */
function usePersistedState<T>(key: string, initialValue: T | (() => T)) {
  // Load state from localStorage on initial render
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        return JSON.parse(storedValue);
      }
      
      // If no stored value, use the initialValue
      return initialValue instanceof Function ? initialValue() : initialValue;
    } catch (error) {
      logger.error('usePersistedState', `Error loading state from localStorage for key ${key}:`, error);
      return initialValue instanceof Function ? initialValue() : initialValue;
    }
  });

  // Update localStorage when state changes
  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      logger.error('usePersistedState', `Error saving state to localStorage for key ${key}:`, error);
    }
  }, [key, state]);

  return [state, setState] as const;
}

export default usePersistedState;
