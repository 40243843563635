import { memo, useState, useEffect, useMemo } from 'react';
import logger from '~/utils/logger';
import { FormField, Tab, TabGroup, TextareaAutosize } from '~/components/ui';
import { cn, removeFocusRings } from '~/utils';
import { Image } from 'lucide-react';
import FluxTab from './Tabs/FluxTab';
import Dalle3Tab from './Tabs/Dalle3Tab';
import { fluxSchema, dalle3Schema, validators, validateField } from '~/schemas/imageGenerationSchemas';
import { useAuthContext } from '~/hooks/AuthContext';
import { useMediaQuery } from '~/hooks';
import usePersistedState from '~/hooks/usePersistedState';
import './tools.css';

const ImageGenerator = () => {
  // Get the token from the auth context
  const { token } = useAuthContext();
  
  // State for image generation
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = usePersistedState<string | null>('imageGenerator.generatedImage', null);
  const [error, setError] = useState<string | null>(null);
  
  // State for field-specific validation errors
  const [fluxErrors, setFluxErrors] = useState<{
    width?: string;
    height?: string;
    sizePreset?: string;
    stylePreset?: string;
    outputFormat?: string;
    safetyTolerance?: string;
    quality?: string;
    randomSeed?: string;
  }>({});
  
  const [dalle3Errors, setDalle3Errors] = useState<{
    dimensions?: string;
    quality?: string;
    style?: string;
  }>({});
  
  // State for tab selection - initialize from URL if available, then from localStorage
  const [activeTab, setActiveTab] = usePersistedState<number>('imageGenerator.activeTab', () => {
    // Initialize from URL if available
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const tabParam = params.get('tab');
      if (tabParam === 'dalle3') {
        return 1;
      }
    }
    return 0; // Default to Flux tab
  });
  
  // State for form values - Shared
  const [imagePrompt, setImagePrompt] = usePersistedState<string>('imageGenerator.imagePrompt', '');

  // Flux form state
  const [sizePreset, setSizePreset] = usePersistedState<string>('imageGenerator.flux.sizePreset', 'custom');
  const [dimensions, setDimensions] = usePersistedState<string>('imageGenerator.flux.dimensions', '1024x1024');
  // Derive width and height from dimensions string
  const [width, height] = dimensions.split('x').map(Number);
  const [stylePreset, setStylePreset] = usePersistedState<string>('imageGenerator.flux.stylePreset', 'Photorealistic');
  const [outputFormat, setOutputFormat] = usePersistedState<string>('imageGenerator.flux.outputFormat', 'webp');
  const [safetyTolerance, setSafetyTolerance] = usePersistedState<number>('imageGenerator.flux.safetyTolerance', 2);
  const [outputQuality, setOutputQuality] = usePersistedState<number>('imageGenerator.flux.outputQuality', 80);
  const [promptUpsampling, setPromptUpsampling] = usePersistedState<boolean>('imageGenerator.flux.promptUpsampling', false);
  const [randomSeed, setRandomSeed] = usePersistedState<string>('imageGenerator.flux.randomSeed', '-1');

  // DALLE-3 form state
  const [quality, setQuality] = usePersistedState<string>('imageGenerator.dalle3.quality', 'hd');
  const [style, setStyle] = usePersistedState<string>('imageGenerator.dalle3.style', 'vivid');

  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  // Check if there are any validation errors
  const hasValidationErrors = useMemo(() => {
    if (activeTab === 0) {
      // Check Flux errors
      return (
        Object.values(fluxErrors).some((error) => error !== undefined) ||
        !imagePrompt
      );
    } else {
      // Check DALL-E 3 errors
      return (
        Object.values(dalle3Errors).some((error) => error !== undefined) ||
        !imagePrompt
      );
    }
  }, [activeTab, fluxErrors, dalle3Errors, imagePrompt]);
  
  // Handle form value changes
  const handleSizePresetChange = (value: string) => {
    logger.log('ImageGenerator', 'Size preset changed:', value);
    setSizePreset(value.toLowerCase()); // Ensure lowercase for comparison with 'custom'
  };

  // Handle form value changes with validation
  const handleWidthChange = (value: number | null) => {
    if (value !== null) {
      logger.log('ImageGenerator', 'Width changed:', value);
      const newWidth = value;
      setDimensions(`${newWidth}x${height}`);

      // When width is manually changed, set sizePreset to 'custom'
      if (sizePreset !== 'custom') {
        setSizePreset('custom');
      }
      
      // Validate width
      const errorMessage = validateField(validators.width, newWidth);
      setFluxErrors(prev => ({ ...prev, width: errorMessage }));
    }
  };

  const handleHeightChange = (value: number | null) => {
    if (value !== null) {
      logger.log('ImageGenerator', 'Height changed:', value);
      const newHeight = value;
      setDimensions(`${width}x${newHeight}`);

      // When height is manually changed, set sizePreset to 'custom'
      if (sizePreset !== 'custom') {
        setSizePreset('custom');
      }
      
      // Validate height
      const errorMessage = validateField(validators.height, newHeight);
      setFluxErrors(prev => ({ ...prev, height: errorMessage }));
    }
  };

  // New handler for directly setting dimensions (for DALLE-3)
  const handleDimensionsChange = (value: string) => {
    logger.log('ImageGenerator', 'Dimensions changed:', value);
    setDimensions(value);
    
    // Validate DALL-E 3 dimensions
    const errorMessage = validateField(validators.dalle3_size, value);
    setDalle3Errors(prev => ({ ...prev, dimensions: errorMessage }));
  };

  const handleStylePresetChange = (value: string) => {
    logger.log('ImageGenerator', 'Style preset changed:', value);
    setStylePreset(value);
  };

  const handleOutputFormatChange = (value: string) => {
    logger.log('ImageGenerator', 'Output format changed:', value);
    setOutputFormat(value);
    
    // Validate output format
    const errorMessage = validateField(validators.output_format, value);
    setFluxErrors(prev => ({ ...prev, outputFormat: errorMessage }));
  };

  const handleSafetyToleranceChange = (value: number | null) => {
    if (value !== null) {
      logger.log('ImageGenerator', 'Safety tolerance changed:', value);
      setSafetyTolerance(value);
      
      // Validate safety tolerance
      const errorMessage = validateField(validators.safety_tolerance, value);
      setFluxErrors(prev => ({ ...prev, safetyTolerance: errorMessage }));
    }
  };

  const handleQualityChange = (value: number) => {
    logger.log('ImageGenerator', 'Output quality changed:', value);
    setOutputQuality(value);
    
    // Validate output quality
    const errorMessage = validateField(validators.output_quality, value);
    setFluxErrors(prev => ({ ...prev, quality: errorMessage }));
  };

  const handlePromptUpsamplingChange = (checked: boolean) => {
    logger.log('ImageGenerator', 'Prompt upsampling changed:', checked);
    setPromptUpsampling(checked);
  };

  const handleRandomSeedChange = (value: string) => {
    logger.log('ImageGenerator', 'Random seed changed:', value);
    setRandomSeed(value);
  };

  const handleImagePromptChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    logger.log('ImageGenerator', 'Image prompt changed:', e.target.value);
    setImagePrompt(e.target.value);
    
    // Validate prompt
    const errorMessage = validateField(validators.prompt, e.target.value);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);

    if (index === 0) {
      // Switching to Flux tab
      // Reset Flux-specific state
      setStylePreset('Photorealistic');
      // No need to reset dimensions as they're shared
      
      // Validate Flux fields
      const widthError = validateField(validators.width, width);
      const heightError = validateField(validators.height, height);
      const safetyError = validateField(validators.safety_tolerance, safetyTolerance);
      const qualityError = validateField(validators.output_quality, outputQuality);
      
      setFluxErrors({
        width: widthError,
        height: heightError,
        safetyTolerance: safetyError,
        quality: qualityError,
      });
    } else {
      // Switching to DALLE-3 tab
      // Reset DALL-E 3 specific state and clear stylePreset to avoid it carrying over
      setStylePreset('');

      // Ensure dimensions are valid for DALLE-3
      const isDalle3Compatible =
        dimensions === '1024x1024' ||
        dimensions === '1792x1024' ||
        dimensions === '1024x1792';

      if (!isDalle3Compatible) {
        logger.log(
          'ImageGenerator',
          'Setting default DALLE-3 dimensions (1024x1024)',
        );
        setDimensions('1024x1024');
      }
      
      // Validate DALL-E 3 fields
      const dimensionsError = validateField(validators.dalle3_size, dimensions);
      const styleError = validateField(validators.dalle3_style, style);
      const qualityError = validateField(validators.dalle3_quality, quality);
      
      setDalle3Errors({
        dimensions: dimensionsError,
        style: styleError,
        quality: qualityError,
      });
    }

    // Clear any previous error messages when switching tabs
    setError(null);
  };
  
  // Validate initial values when component mounts
  useEffect(() => {
    // Validate initial values based on active tab
    if (activeTab === 0) {
      // Validate Flux fields
      const widthError = validateField(validators.width, width);
      const heightError = validateField(validators.height, height);
      const safetyError = validateField(validators.safety_tolerance, safetyTolerance);
      const qualityError = validateField(validators.output_quality, outputQuality);
      
      setFluxErrors({
        width: widthError,
        height: heightError,
        safetyTolerance: safetyError,
        quality: qualityError,
      });
    } else {
      // Validate DALL-E 3 fields
      const dimensionsError = validateField(validators.dalle3_size, dimensions);
      const styleError = validateField(validators.dalle3_style, style);
      const qualityError = validateField(validators.dalle3_quality, quality);
      
      setDalle3Errors({
        dimensions: dimensionsError,
        style: styleError,
        quality: qualityError,
      });
    }
  }, []);

  // Helper function to handle API response
  const handleApiResponse = async (response: Response) => {
    // Clone the response before reading it
    const responseClone = response.clone();

    if (!response.ok) {
      try {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate image');
      } catch (jsonError) {
        // If the response is not valid JSON, get the text instead
        const errorText = await responseClone.text();
        throw new Error(errorText || 'Failed to generate image');
      }
    }

    let result;
    try {
      result = await responseClone.json();
    } catch (jsonError) {
      throw new Error('Invalid response from server');
    }

    logger.log('ImageGenerator', 'Image generated successfully', result);

    // Handle the result (display the generated image)
    setGeneratedImage(result.filepath);
  };

  const handleGenerate = async () => {
    logger.log('ImageGenerator', 'Generate button clicked');
    setError(null);
    setFluxErrors({});
    setDalle3Errors({});

    try {
      const endpoint =
        activeTab === 0
          ? '/api/tools/generate-image/flux'
          : '/api/tools/generate-image/dalle';

      // Prepare the appropriate payload based on the active tab
      if (activeTab === 0) {
        // Flux API
        const fluxPayload = {
          prompt: imagePrompt,
          width,
          height,
          stylePreset,
          aspect_ratio: sizePreset === 'custom' ? 'custom' : sizePreset,
          output_format: outputFormat,
          safety_tolerance: safetyTolerance,
          prompt_upsampling: promptUpsampling,
          seed: randomSeed !== '-1' ? randomSeed : undefined, // Pass as string, let Zod handle conversion
          output_quality: outputQuality,
        };

        logger.log(
          'ImageGenerator',
          'Flux payload before validation:',
          fluxPayload,
        );

        // Validate with Zod schema
        const validationResult = fluxSchema.safeParse(fluxPayload);

        if (!validationResult.success) {
          // Extract field-specific errors
          const fieldErrors: Record<string, string> = {};
          validationResult.error.errors.forEach((err) => {
            const path = err.path.join('.');
            fieldErrors[path] = err.message;
          });
          
          // Set field-specific errors
          setFluxErrors(fieldErrors);
          
          // Set general error message
          const errorMessage =
            validationResult.error.errors[0]?.message || 'Invalid form data';
          setError(errorMessage);
          
          logger.error(
            'ImageGenerator',
            'Flux validation error:',
            validationResult.error,
          );
          return;
        }
        
        // Clear errors on successful validation
        setFluxErrors({});

        logger.log(
          'ImageGenerator',
          'Flux validation successful, sending request to',
          endpoint,
        );

        setIsGenerating(true);

        // Send validated payload
        // Log the token from the auth context
        logger.log(
          'ImageGenerator',
          'Token from auth context:',
          token ? 'Found' : 'Not found',
        );

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
          credentials: 'include', // This is important to include cookies
          body: JSON.stringify(validationResult.data), // Use the validated data
        });

        // Handle response
        await handleApiResponse(response);
      } else {
        // DALL-E 3 API
        const dalle3Payload = {
          model: 'dall-e-3',
          prompt: imagePrompt, // Don't include stylePreset for DALL-E 3
          n: 1,
          size: dimensions, // Use the exact dimensions string from the dropdown
          quality,
          style,
        };

        logger.log(
          'ImageGenerator',
          'DALL-E 3 payload before validation:',
          dalle3Payload,
        );

        // Validate with Zod schema
        const validationResult = dalle3Schema.safeParse(dalle3Payload);

        if (!validationResult.success) {
          // Extract field-specific errors
          const fieldErrors: Record<string, string> = {};
          validationResult.error.errors.forEach((err) => {
            const path = err.path.join('.');
            
            // Map path to field name for DALL-E 3
            if (path === 'size') {
              fieldErrors['dimensions'] = err.message;
            } else {
              fieldErrors[path] = err.message;
            }
          });
          
          // Set field-specific errors
          setDalle3Errors(fieldErrors);
          
          // Set general error message
          const errorMessage =
            validationResult.error.errors[0]?.message || 'Invalid form data';
          setError(errorMessage);
          
          logger.error(
            'ImageGenerator',
            'DALL-E 3 validation error:',
            validationResult.error,
          );
          return;
        }
        
        // Clear errors on successful validation
        setDalle3Errors({});

        logger.log(
          'ImageGenerator',
          'DALL-E 3 validation successful, sending request to',
          endpoint,
        );

        setIsGenerating(true);

        // Send validated payload
        // Log the token from the auth context
        logger.log(
          'ImageGenerator',
          'Token from auth context for DALL-E:',
          token ? 'Found' : 'Not found',
        );

        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token}` }),
          },
          credentials: 'include', // This is important to include cookies
          body: JSON.stringify(validationResult.data), // Use the validated data
        });

        // Handle response
        await handleApiResponse(response);
      }
    } catch (error) {
      logger.error('ImageGenerator', 'Error generating image:', error);
      setError(
        error instanceof Error ? error.message : 'Failed to generate image',
      );
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="relative flex h-full w-full flex-col items-center overflow-y-auto bg-white px-6 pb-3 pt-6 text-[#0F172A] md:px-8 md:pt-[80px] dark:bg-[#0F172A] dark:text-white">
      {/* Header */}
      <div className="mb-8 flex flex-col items-center md:px-16">
        <div className="mb-4 flex items-center gap-2">
          <Image
            size={isSmallScreen ? 36 : 48}
            className="text-[#0F172A] dark:text-white"
          />
          <h1 className="text-[28px] font-medium leading-[36px] tracking-[-.225px] text-[#0F172A] md:ml-4 md:text-[48px] md:font-bold md:leading-normal md:tracking-[-.576px] dark:text-white">
            Image Generator Tool
          </h1>
        </div>
        <p className="font-base max-w-2xl text-center text-[12px] font-medium leading-[14px] text-[#0F172A] md:text-[18px] md:leading-[24px] dark:text-white">
          Generate images using either Flux or Dalle-3
        </p>
      </div>

      {/* Tab Navigation */}
      <div className="mb-8 w-full">
        <TabGroup
          className="flex flex-row items-center justify-between md:justify-center md:gap-x-8 md:pb-4"
          index={activeTab}
          onChange={handleTabChange}
        >
          <Tab urlValue="flux" className="md:px-16 md:py-2.5">
            Generate with Flux
          </Tab>
          <Tab urlValue="dalle3" className="md:px-16 md:py-2.5">
            Generate with Dalle-3
          </Tab>
        </TabGroup>
      </div>

      {/* Image Prompt Section */}
      <div className="mt-8 flex w-full max-w-5xl flex-col space-y-8">
        <div className="mx-auto flex flex-col items-center justify-center space-y-4 w-full">
          <FormField  label="Image Prompt" tooltip='Describe your image in detail' labelClassName='text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white'>
            <div className="relative flex w-full justify-center">
              <div
                className="relative flex w-full overflow-hidden rounded-lg border border-transparent"
                style={{
                  background:
                    'linear-gradient(91.969deg, #F214A1 0%, #BC0278 100%)',
                }}
              >
                <div
                  className="relative flex h-full w-full flex-grow flex-row items-center overflow-hidden bg-[#F2F2F2] p-1 dark:bg-[#1c2846]"
                  style={{
                    backgroundClip: 'padding-box',
                  }}
                >
                  <TextareaAutosize
                    value={imagePrompt}
                    onChange={(e) => handleImagePromptChange(e)}
                    placeholder={
                      isSmallScreen
                        ? 'Describe your image'
                        : 'Describe your image in detail'
                    }
                    id="image-prompt-input"
                    tabIndex={0}
                    data-testid="image-prompt-input"
                    style={{ height: 44 }}
                    rows={4}
                    className={cn(
                      'm-0 max-h-[65vh] w-full resize-none bg-[#F2F2F2] py-[13px] text-[#0F172A] placeholder:text-[#0F172A/60] md:max-h-[75vh] md:py-3.5 dark:bg-[#1c2846] dark:text-white dark:placeholder:text-white/60 [&:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)]',
                      'pl-4 pr-4',
                      removeFocusRings,
                    )}
                  />
                </div>
              </div>
            </div>
          </FormField>
        </div>
        
        <div className="w-full flex justify-end mt-4">
          <button
            className={cn('button-gradient rounded-lg p-2 text-white transition-colors md:px-6 md:py-3 flex items-center justify-center min-w-[120px]', isSmallScreen && 'w-full')}
            onClick={handleGenerate}
            disabled={hasValidationErrors || isGenerating}
          >
            {isGenerating && (
              <>
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-b-transparent border-white"></div>
                Generating...
              </>
            )}
            {!isGenerating && isSmallScreen && (
              <div className="flex items-center">
                <span className="mr-1">Generate</span>
              </div>
            )}
            {!isGenerating && !isSmallScreen && 'Generate'}
          </button>
        </div>
        
        {error && (
          <div className="mt-4 rounded-md bg-red-100 p-3 text-red-700 dark:bg-red-900/30 dark:text-red-400">
            {error}
          </div>
        )}

        {/* Main Content */}
        <div className="w-full max-w-5xl md:pb-8">
          {activeTab === 0 ? (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-16">
              <FluxTab
                width={width}
                height={height}
                sizePreset={sizePreset}
                stylePreset={stylePreset}
                outputFormat={outputFormat}
                safetyTolerance={safetyTolerance}
                quality={outputQuality}
                promptUpsampling={promptUpsampling}
                randomSeed={randomSeed}
                handleWidthChange={handleWidthChange}
                handleHeightChange={handleHeightChange}
                handleSizePresetChange={handleSizePresetChange}
                handleStylePresetChange={handleStylePresetChange}
                handleOutputFormatChange={handleOutputFormatChange}
                handleSafetyToleranceChange={handleSafetyToleranceChange}
                handleQualityChange={handleQualityChange}
                handlePromptUpsamplingChange={handlePromptUpsamplingChange}
                handleRandomSeedChange={handleRandomSeedChange}
                errors={fluxErrors}
              />
            </div>
          ) : (
            <Dalle3Tab
              width={width}
              height={height}
              quality={quality}
              style={style}
              handleWidthChange={handleWidthChange}
              handleHeightChange={handleHeightChange}
              setQuality={setQuality}
              setStyle={setStyle}
              handleDimensionsChange={handleDimensionsChange}
              errors={dalle3Errors}
            />
          )}
        </div>

        {generatedImage && !isGenerating && (
          <div className="mt-4">
            <h3 className="text-primary mb-2 text-lg font-medium">
              Generated Image
            </h3>
            <div className="overflow-hidden rounded-lg border border-gray-300 dark:border-gray-700">
              <img
                src={generatedImage}
                alt="Generated result"
                className="max-h-[600px] w-full object-contain"
              />
            </div>
            <div className="mt-2 text-center">
              <a
                href={generatedImage}
                download
                className="button-gradient inline-flex items-center rounded-md px-3 py-2 text-sm text-white hover:opacity-90"
              >
                Download Image
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ImageGenerator);
