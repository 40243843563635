import {
  useMemo,
  useState,
  useEffect,
  ReactNode,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { setTokenHeader, SystemRoles } from 'librechat-data-provider';
import {
  useGetUserQuery,
  useLoginUserMutation,
  useRefreshTokenMutation,
} from 'librechat-data-provider/react-query';
import type { TLoginResponse, TLoginUser } from 'librechat-data-provider';
import { TAuthConfig, TUserContext, TAuthContext, TResError } from '~/common';
import { useLogoutUserMutation, useGetRole } from '~/data-provider';
import useTimeout from './useTimeout';
import store from '~/store';
import { logger } from '~/utils';

const AuthContext = createContext<TAuthContext | undefined>(undefined);

const AuthContextProvider = ({
  authConfig,
  children,
}: {
  authConfig?: TAuthConfig;
  children: ReactNode;
}) => {
  const [user, setUser] = useRecoilState(store.user);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [initialAuthCheckDone, setInitialAuthCheckDone] = useState<boolean>(false);
  const { data: userRole = null } = useGetRole(SystemRoles.USER, {
    enabled: !!(isAuthenticated && (user?.role ?? '')),
  });
  const { data: adminRole = null } = useGetRole(SystemRoles.ADMIN, {
    enabled: !!(isAuthenticated && user?.role === SystemRoles.ADMIN),
  });

  const navigate = useNavigate();

  const setUserContext = useCallback(
    (userContext: TUserContext) => {
      const { token, isAuthenticated, user, redirect } = userContext;
      if (user) {
        setUser(user);
      }
      setToken(token);
      //@ts-ignore - ok for token to be undefined initially
      setTokenHeader(token);
      setIsAuthenticated(isAuthenticated);
      if (redirect != null && redirect) {
        navigate(redirect, { replace: true });
      }
    },
    [navigate, setUser],
  );
  const doSetError = useTimeout({ callback: (error) => setError(error as string | undefined) });

  const loginUser = useLoginUserMutation();
  const logoutUser = useLogoutUserMutation({
    onSuccess: () => {
      setUserContext({
        token: undefined,
        isAuthenticated: false,
        user: undefined,
        redirect: '/login',
      });
    },
    onError: (error) => {
      doSetError((error as Error).message);
      setUserContext({
        token: undefined,
        isAuthenticated: false,
        user: undefined,
        redirect: '/login',
      });
    },
  });

  const logout = useCallback(() => logoutUser.mutate(undefined), [logoutUser]);
  const userQuery = useGetUserQuery({ enabled: !!token });

  const silentRefresh = useCallback(async () => {
    if (authConfig?.test) {
      logger.info('[AuthContext] Test mode. Skipping silent refresh.');
      return;
    }
    logger.info('[AuthContext] Starting silent refresh');
    try {
      logger.info('[AuthContext] Making refresh request with cookies:', document.cookie);
      
      const response = await fetch('/api/auth/refresh', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      logger.info('[AuthContext] Refresh response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        logger.error('[AuthContext] Refresh failed with status', response.status, 'and body:', errorText);
        throw new Error(`Refresh failed: ${errorText}`);
      }

      const data = await response.json();
      logger.info('[AuthContext] Silent refresh response data:', data);

      if (data.token) {
        logger.info('[AuthContext] Setting user context with token');
        setUserContext({ token: data.token, isAuthenticated: true, user: data.user });
      } else {
        logger.info('[AuthContext] No token in response. Current cookies:', document.cookie);
        if (authConfig?.test) {
          return;
        }
        navigate('/login');
      }
    } catch (error) {
      console.error('[AuthContext] Silent refresh error:', error);
      if (authConfig?.test) {
        return;
      }
      navigate('/login');
    }
  }, [authConfig, navigate, setUserContext]);

  // Handle initial auth check
  useEffect(() => {
    const checkInitialAuth = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!token && !isAuthenticated && !initialAuthCheckDone) {
        logger.info('[AuthContext] Performing initial auth check');
        await silentRefresh();
      }
      setInitialAuthCheckDone(true);
    };

    checkInitialAuth();
  }, [token, isAuthenticated, initialAuthCheckDone, silentRefresh]);

  useEffect(() => {
    if (userQuery.data) {
      setUser(userQuery.data);
    } else if (userQuery.isError) {
      console.error('[AuthContext] User query error:', userQuery.error);
      doSetError((userQuery.error as Error).message);
      navigate('/login', { replace: true });
    }

    if (error && isAuthenticated) {
      logger.info('[AuthContext] Clearing error for authenticated user');
      doSetError(undefined);
    }
  }, [
    token,
    isAuthenticated,
    userQuery.data,
    userQuery.isError,
    userQuery.error,
    error,
    navigate,
    setUser,
    doSetError,
    initialAuthCheckDone,
  ]);

  useEffect(() => {
    const handleTokenUpdate = (event: CustomEvent) => {
      logger.info('[AuthContext] Token update event received:', event.detail);
      const { token, user, isAuthenticated } = event.detail;
      setUserContext({
        token,
        isAuthenticated,
        user,
      });
    };

    window.addEventListener('tokenUpdated', handleTokenUpdate as EventListener);

    return () => {
      window.removeEventListener('tokenUpdated', handleTokenUpdate as EventListener);
    };
  }, [setUserContext]);

  const login = (data: TLoginUser) => {
    loginUser.mutate(data, {
      onSuccess: (data: TLoginResponse) => {
        const { user, token } = data;
        setError(undefined);
        setUserContext({ token, isAuthenticated: true, user, redirect: '/c/new' });
      },
      onError: (error: TResError | unknown) => {
        const resError = error as TResError;
        doSetError(resError.message);
        navigate('/login', { replace: true });
      },
    });
  };

  // Make the provider update only when it should
  const memoedValue = useMemo(
    () => ({
      user,
      token,
      error,
      login,
      logout,
      setError,
      roles: {
        [SystemRoles.USER]: userRole,
        [SystemRoles.ADMIN]: adminRole,
      },
      isAuthenticated,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, error, isAuthenticated, token, userRole, adminRole],
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext should be used inside AuthProvider');
  }

  return context;
};

export { AuthContextProvider, useAuthContext };
