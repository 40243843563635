import * as React from 'react';
import { cn } from '~/utils';

interface InlineMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const InlineMessage = React.forwardRef<HTMLDivElement, InlineMessageProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex items-center gap-2.5 rounded-lg border border-[#35598D] bg-[#BAD3F7] px-4 py-2.5 text-[14px] font-medium tracking-[-0.1px] text-[#35598D]',
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

InlineMessage.displayName = 'InlineMessage';

export { InlineMessage };
