import { useState } from 'react';
import { cn } from '~/utils';

interface ThinkingSectionProps {
  content: string;
}

export default function ThinkingSection({ content }: ThinkingSectionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!content) {
    return null;
  }

  return (
    <div className="mb-2">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={cn(
          'flex items-center gap-1 text-xs text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200',
          'transition-colors duration-200',
        )}
      >
        <svg
          className={cn(
            'h-3 w-3 transform transition-transform duration-200',
            isExpanded ? 'rotate-90' : '',
          )}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
        Thinking Process
      </button>
      {isExpanded && (
        <div className="mt-2 rounded-md bg-[#F2F2F2] p-3 pt-3.5  text-[16px] text-gray-700 dark:bg-[#1c2846] dark:text-white">
          {content}
        </div>
      )}
    </div>
  );
}
