/**
 * Formats camelCase or PascalCase to space-separated words
 * with special handling for common acronyms and tool names
 *
 * @param name - The camelCase or PascalCase string to format
 * @returns The formatted string with proper spacing and acronym handling
 */
export function formatToolName(name: string): string {
  // Add a space before each capital letter and trim any leading space
  let formatted = name.replace(/([A-Z])/g, ' $1').trim();

  // Handle special cases for common acronyms and tool names
  formatted = formatted
    // Common acronyms
    .replace(/E M V/, 'EMV')
    .replace(/G P T/, 'GPT')
    .replace(/A I/, 'AI')
    .replace(/U R L/, 'URL')
    .replace(/A P I/, 'API')
    // Tool-specific names
    .replace(/Atlas Contextual Search/, 'Atlas Contextual Search')
    .replace(/G P T Researcher/, 'GPT Researcher')
    .replace(/Azure A I Search/, 'Azure AI Search');

  return formatted;
}
