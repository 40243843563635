import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cn } from '../../utils';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('flex flex-col gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

interface RadioButtonProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  buttonTitle?: string;
}

const RadioButton = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioButtonProps
>(({ className, buttonTitle, ...props }, ref) => {
  return (
    <div className="flex items-center gap-2.5">
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(
          'h-5 w-5 rounded-full border border-[#6A6A6A] bg-[#CBCACA] disabled:cursor-not-allowed disabled:opacity-50 dark:border-[#E2E8F0] dark:bg-[#909BB5]',
          className,
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <div className="h-2.5 w-2.5 rounded-full bg-[#0F172A] dark:bg-[#060B17]" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>

      {buttonTitle ? (
        <label
          className="text-primary cursor-pointer text-[20px] font-medium"
          htmlFor={props.id}
        >
          {buttonTitle}
        </label>
      ) : null}
    </div>
  );
});
RadioButton.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioButton };
