import * as React from 'react';
import { Input } from '~/components/ui/Input';
import { cn } from '~/utils';

// Define style variants
const dropdownVariants = {
  inputContainer: 'relative',
  dropdownButton:
    'dark:text-white text-[#6A6A6A] focus-visible:ring-ring-primary absolute inset-y-0 right-0 flex items-center rounded-md px-2 focus-visible:outline-none focus-visible:ring-1 transition-all duration-300 ease-in-out',
  dropdownList:
    'border-[#6A6A6A] dark:border-[#273558] dark:bg-[#1C2846] bg-[#F2F2F2] focus:ring-ring-primary absolute z-10 mt-2 max-h-60 w-full p-1 overflow-auto rounded-md border shadow-lg focus:ring-1 focus:ring-inset',
  dropdownItem: {
    base: 'cursor-pointer rounded-md px-3 py-2 text-[#6A6A6A] dark:text-white',
    focus:
      'focus:bg-[#D3D1D1] focus:ring-ring-primary focus:outline-none focus:ring-1 focus:ring-inset',
    active: 'text-primary bg-surface-active',
    inactive:
      'text-[#6A6A6A] dark:text-white hover:bg-[#D3D1D1] dark:hover:bg-[#2F3F66]',
  },
};

export type InputWithDropdownProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    options: string[];
    onSelect?: (value: string) => void;
    readOnly?: boolean; // New prop to disable input functionality
    error?: boolean;
    errorMessage?: string;
  };

const InputWithDropdown = React.forwardRef<
  HTMLInputElement,
  InputWithDropdownProps
>(({ className, options, onSelect, readOnly, error, errorMessage, ...props }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(
    (props.value as string) || '',
  );
  const [highlightedIndex, setHighlightedIndex] = React.useState(-1);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSelect = (value: string) => {
    setInputValue(value);
    setIsOpen(false);
    setHighlightedIndex(-1);
    if (onSelect) {
      onSelect(value);
    }
    if (props.onChange) {
      props.onChange({
        target: { value },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only update input value if not in readOnly mode
    if (!readOnly) {
      setInputValue(e.target.value);
      if (props.onChange) {
        props.onChange(e);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
        } else {
          setHighlightedIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex,
          );
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        break;
      case 'Enter':
        e.preventDefault();
        if (isOpen && highlightedIndex !== -1) {
          handleSelect(options[highlightedIndex]);
        }
        setIsOpen(false);
        break;
      case 'Escape':
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={inputRef}>
      <div className={dropdownVariants.inputContainer}>
        <Input
          {...props}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          aria-haspopup="listbox"
          aria-controls="dropdown-list"
          className={cn('', className ?? '', readOnly && 'cursor-default')}
          ref={ref}
          readOnly={readOnly}
          error={error}
          errorMessage={errorMessage}
        />
        <button
          type="button"
          className={dropdownVariants.dropdownButton}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? 'Close dropdown' : 'Open dropdown'}
        >
          <svg
            className={cn(
              'h-5 w-5 transition-transform duration-300 ease-in-out',
              isOpen && 'rotate-180',
            )}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        {isOpen && (
          <ul
            id="dropdown-list"
            role="listbox"
            className={dropdownVariants.dropdownList}
          >
            {options.map((option, index) => (
              <li
                key={index}
                role="option"
                aria-selected={index === highlightedIndex}
                className={cn(
                  dropdownVariants.dropdownItem.base,
                  dropdownVariants.dropdownItem.focus,
                  index === highlightedIndex
                    ? dropdownVariants.dropdownItem.active
                    : dropdownVariants.dropdownItem.inactive,
                )}
                onClick={() => handleSelect(option)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleSelect(option);
                  }
                }}
                tabIndex={0}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
});

InputWithDropdown.displayName = 'InputWithDropdown';

export default InputWithDropdown;
