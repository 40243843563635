import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { QueryKeys, Constants } from 'librechat-data-provider';
import type {
  TMessage,
  TConversation,
  ConversationListResponse,
} from 'librechat-data-provider';
import type { Dispatch, SetStateAction } from 'react';
import {
  useLocalize,
  useNewConvo,
  useNavScrolling,
  useConversations,
  useAuthContext,
  useMediaQuery,
} from '~/hooks';
import { useConversationsInfiniteQuery } from '~/data-provider';
import { MobileToolBar } from '~/components/ToolBar';
import { Conversations } from '~/components/Conversations';
import { useSearchContext } from '~/Providers';
import { Spinner } from '~/components/svg';
import { cn } from '~/utils';
import { logger } from '~/utils';
import store from '~/store';
import { Plus } from 'lucide-react';

export default function MobileNav() {
  const localize = useLocalize();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { newConversation } = useNewConvo(0);
  const conversation = useRecoilValue(store.conversationByIndex(0));
  const { title = 'New Chat' } = conversation || {};

  const { isAuthenticated } = useAuthContext();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  
  // Function to determine if Ayzenberg AI tool is active
  const isAyzenbergAIActive = useCallback(() => {
    const pathname = location.pathname;
    
    // Check if we're in a conversation path that's not a specific tool
    // Ayzenberg AI paths are typically /c/[conversationId] but not /c/tools/...
    if (pathname.startsWith('/c/')) {
      // If the path includes 'tools', it's not Ayzenberg AI
      return !pathname.includes('/tools/');
    }
    
    // Default routes like / or /chat should also be considered Ayzenberg AI
    return pathname === '/' || pathname === '/chat';
  }, [location]);

  // States for the mobile toolbar and chat history dropdowns
  const [isToolbarOpen, setIsToolbarOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const historyDropdownRef = useRef<HTMLDivElement>(null);
  const [showLoading, setShowLoading] = useState(false);

  // Data fetching for conversations
  const { refreshConversations } = useConversations();
  const { pageNumber, searchQuery, searchQueryRes } = useSearchContext();
  const [tags, setTags] = useState<string[]>([]);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useConversationsInfiniteQuery(
      {
        pageNumber: pageNumber.toString(),
        isArchived: false,
        tags: tags.length === 0 ? undefined : tags,
      },
      { enabled: isAuthenticated },
    );

  // Simple direct state setting functions instead of toggles
  const openToolbar = () => {
    logger.log('MobileNav', 'Opening toolbar');
    setIsToolbarOpen(true);
    if (isHistoryOpen) {
      setIsHistoryOpen(false);
    }
  };

  const closeToolbar = () => {
    logger.log('MobileNav', 'Closing toolbar');
    setIsToolbarOpen(false);
  };

  const openHistory = () => {
    logger.log('MobileNav', 'Opening chat history');
    setIsHistoryOpen(true);
    if (isToolbarOpen) {
      setIsToolbarOpen(false);
    }
  };

  const closeHistory = () => {
    logger.log('MobileNav', 'Closing chat history');
    setIsHistoryOpen(false);
  };

  // Handler for toolbar button
  const handleToolbarClick = () => {
    if (isToolbarOpen) {
      closeToolbar();
    } else {
      openToolbar();
    }
  };

  // Handler for history button
  const handleHistoryClick = () => {
    if (isHistoryOpen) {
      closeHistory();
    } else {
      openHistory();
    }
  };

  // Scroll handling for conversations list
  const { containerRef, moveToTop } = useNavScrolling<ConversationListResponse>(
    {
      setShowLoading,
      hasNextPage: searchQuery ? searchQueryRes?.hasNextPage : hasNextPage,
      fetchNextPage: searchQuery
        ? searchQueryRes?.fetchNextPage
        : fetchNextPage,
      isFetchingNextPage: searchQuery
        ? searchQueryRes?.isFetchingNextPage ?? false
        : isFetchingNextPage,
    },
  );

  // Memoized conversations data
  const conversations = useMemo(
    () =>
      (searchQuery ? searchQueryRes?.data : data)?.pages.flatMap(
        (page) => page.conversations,
      ) || [],
    [data, searchQuery, searchQueryRes?.data],
  );

  // Close history dropdown when clicking outside
  const itemToggleNav = () => {
    setIsHistoryOpen(false);
  };

  // Log state changes for debugging
  useEffect(() => {
    logger.log(
      'MobileNav',
      `Toolbar state changed to: ${isToolbarOpen ? 'open' : 'closed'}`,
    );
  }, [isToolbarOpen]);

  useEffect(() => {
    logger.log(
      'MobileNav',
      `History state changed to: ${isHistoryOpen ? 'open' : 'closed'}`,
    );
  }, [isHistoryOpen]);

  // Close history dropdown when clicking outside or pressing Escape
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Only process if the history dropdown is open
      if (!isHistoryOpen) {
        return;
      }

      // Check if the click was outside the dropdown
      if (
        historyDropdownRef.current &&
        !historyDropdownRef.current.contains(event.target as Node)
      ) {
        // Check if the click was on a nav button (which has its own handler)
        const target = event.target as Element;
        const isNavButton =
          target.closest('[data-testid="mobile-header-new-chat-button"]') ||
          target.closest('[data-nav-button]');

        if (!isNavButton) {
          logger.log('MobileNav', 'Closing history from click outside');
          setIsHistoryOpen(false);
        }
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        logger.log('MobileNav', 'Closing history from Escape key');
        setIsHistoryOpen(false);
      }
    };

    // Only add the listeners if the history dropdown is open
    if (isHistoryOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isHistoryOpen]);

  return (
    <div className="relative">
      <div className="absolute left-0 right-0 top-0 h-[44px] bg-[#F2F2F2] md:hidden dark:bg-[#060B17]" />
      <div className="sticky top-0 z-[60] flex min-h-[40px] items-center bg-[#F2F2F2] pl-1 text-[#060B17] md:hidden dark:bg-[#435880] dark:text-white">
        {/* Hamburger menu - always on the left */}
        <div className="flex-none">
          <button
            type="button"
            data-testid="mobile-header-new-chat-button"
            aria-label={localize('com_ui_tools')}
            className={'relative z-[70] m-1 inline-flex size-10 items-center justify-center rounded-full outline-none focus:outline-none focus:ring-0 focus:ring-offset-0'}
            onClick={handleToolbarClick}
          >
            <span className="sr-only">{localize('com_ui_tools')}</span>
            {isToolbarOpen ? (
              // X icon when toolbar is open
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-md"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              // Hamburger icon when toolbar is closed
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-md"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </button>
        </div>
        
        {/* Center and right content - only visible for Ayzenberg AI */}
        {isAyzenbergAIActive() ? (
          <>
            <button
              type="button"
              onClick={handleHistoryClick}
              data-nav-button="history"
              className="relative z-[70] flex flex-1 items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm font-normal text-[#0F172A] dark:text-white outline-none focus:outline-none focus:ring-0 focus:ring-offset-0"
            >
              <span className="truncate">
                {title || localize('com_ui_new_chat')}
              </span>
              {/* Chevron icon that rotates based on dropdown state */}
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`ml-1 transition-transform ${
                  isHistoryOpen ? 'rotate-180' : ''
                }`}
              >
                <path
                  d="M6 9l6 6 6-6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button
              type="button"
              aria-label={localize('com_ui_new_chat')}
              className="hover:bg-surface-hover m-1 inline-flex size-10 items-center justify-center rounded-full outline-none focus:outline-none focus:ring-0 focus:ring-offset-0"
              onClick={() => newConversation()}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon-md"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </>
        ) : (
          // Empty flex-grow div to push the hamburger to the left when Ayzenberg AI is not active
          <div className="flex-1"></div>
        )}
      </div>

      {/* Render the mobile toolbar dropdown */}
      {/* Mobile toolbar dropdown */}
      <MobileToolBar
        isOpen={isToolbarOpen}
        onClose={() => {
          logger.log('MobileNav', 'onClose called for toolbar');
          setIsToolbarOpen(false);
        }}
      />

      {/* Chat history dropdown - only show when Ayzenberg AI is active */}
      {isAyzenbergAIActive() && (
        <div
          className={`fixed inset-0 z-50 transition-opacity duration-300 ease-in-out ${
            isHistoryOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
          }`}
          data-testid="mobile-history-container"
        >
          {/* Semi-transparent backdrop - positioned below the nav bar */}
          <button
            className={`absolute inset-x-0 bottom-0 top-[44px] bg-black transition-opacity duration-300 ease-in-out ${
              isHistoryOpen ? 'opacity-50' : 'pointer-events-none opacity-0'
            }`}
            onClick={(e) => {
              // Make sure the click event doesn't propagate up
              e.stopPropagation();
              setIsHistoryOpen(false);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape' || e.key === 'Enter' || e.key === ' ') {
                setIsHistoryOpen(false);
              }
            }}
            aria-label="Close menu"
            tabIndex={isHistoryOpen ? 0 : -1}
          />
          <div
            ref={historyDropdownRef}
            className={`absolute left-0 right-0 top-[44px] max-h-[70vh] flex flex-col bg-[#f2f2f2] dark:bg-[#0f172a] shadow-lg transition-all duration-300 ease-in-out ${
              isHistoryOpen
                ? 'translate-y-0 opacity-100'
                : 'translate-y-[-20px] opacity-0'
            }`}
          >
            {/* Scrollable content area with header inside */}
            <div className="flex-1 overflow-y-auto p-2" ref={containerRef}>
              {/* Header */}
              <div className="mb-4 text-center">
                <h2 className="text-lg font-medium text-[#0f172a] dark:text-white">
                  Chat History
                </h2>
              </div>
              <Conversations
                conversations={conversations}
                moveToTop={moveToTop}
                toggleNav={itemToggleNav}
              />
              {(isFetchingNextPage || showLoading) && (
                <Spinner
                  className={cn('text-text-primary m-1 mx-auto mb-4 h-4 w-4')}
                />
              )}
            </div>
            
            {/* Fixed button at the bottom */}
            <div className="p-2 bg-[#f2f2f2] dark:bg-[#0f172a]">
              <button
                onClick={() => {
                  newConversation();
                  setIsHistoryOpen(false);
                }}
                className="flex w-full items-center justify-center gap-2 rounded-lg bg-[#FFFFFF] p-3 text-sm font-medium text-[#0F172A] shadow-md hover:bg-[#F8FAFC] dark:bg-[#1C2846] dark:text-white dark:hover:bg-[#2D3F6E] outline-none focus:outline-none focus:ring-0 focus:ring-offset-0"
                data-testid="mobile-history-new-chat-button"
              >
                <Plus className="h-5 w-5" />
                <span className="font-base">{localize('com_ui_new_chat')}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
