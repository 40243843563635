import { useCallback, useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalize } from '~/hooks';
import { cn } from '~/utils';
import { logger } from '~/utils';
import { TOOLS } from './ToolsConfig';
import type { ToolItem } from './types';
import AccountSettings from '~/components/Nav/AccountSettings';
import { useMediaQuery } from '~/hooks';
import '~/routes/Tools/tools.css'; // Import the CSS file with toolbar-button classes

interface MobileToolBarProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileToolBar = ({ isOpen, onClose }: MobileToolBarProps) => {
  const localize = useLocalize();
  const navigate = useNavigate();
  // Use useLocation to get the current location (path and query params)
  const location = useLocation();
  
  // Function to determine if a tool is active based on the current path
  const isToolActive = useCallback((toolId: string) => {
    const pathname = location.pathname;
    
    // Check if the image generator tool is active
    const isImageGeneratorActive = pathname.includes('/tools/image-generator');
    
    // For the AI Chats tool (home button), it should be active by default
    // unless another specific tool path matches
    if (toolId === '/c/new') {
      return !isImageGeneratorActive;
    }
    
    // For the Image Generator tool
    if (toolId === '/c/tools/image-generator') {
      return isImageGeneratorActive;
    }
    
    // For any other tools that might be added in the future
    return pathname.startsWith(toolId);
  }, [location]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Only process if the toolbar is open
      if (!isOpen) {
        return;
      }

      // Check if the click was outside the dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        // Check if the click was on a nav button (which has its own handler)
        const target = event.target as Element;
        const isNavButton =
          target.closest('[data-testid="mobile-header-new-chat-button"]') ||
          target.closest('[data-nav-button]');

        if (!isNavButton) {
          logger.log('MobileToolBar', 'Closing toolbar from click outside');
          onClose();
        }
      }
    };

    // Only add the listener if the toolbar is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleToolClick = useCallback(
    (tool: ToolItem) => {
      logger.log('MobileToolBar', 'Navigating to tool:', tool.id);
      navigate(tool.id);
      onClose();
    },
    [navigate, onClose],
  );

  return (
    <div
      className={`fixed inset-0 z-50 transition-opacity duration-300 ease-in-out ${
        isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
      }`}
      data-testid="mobile-toolbar-container"
    >
      {/* Semi-transparent backdrop - positioned below the nav bar */}
      <button
        className={`absolute inset-x-0 bottom-0 top-[44px] bg-black transition-opacity duration-300 ease-in-out ${
          isOpen ? 'opacity-50' : 'pointer-events-none opacity-0'
        }`}
        onClick={(e) => {
          // Make sure the click event doesn't propagate up
          e.stopPropagation();
          logger.log('MobileToolBar', 'Closing toolbar from backdrop click');
          onClose();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape' || e.key === 'Enter' || e.key === ' ') {
            onClose();
          }
        }}
        aria-label="Close menu"
        tabIndex={isOpen ? 0 : -1}
      />
      <div
        ref={dropdownRef}
        className={`absolute left-0 right-0 top-[44px] bg-[#F2F2F2] dark:bg-[#0f172a] p-2 shadow-lg transition-all duration-300 ease-in-out ${
          isOpen ? 'translate-y-0 opacity-100' : 'translate-y-[-20px] opacity-0'
        }`}
      >
        <div className="py-2 text-center text-[#0f172a] dark:text-white">
          <h2 className="mb-2 text-lg font-medium">Tool Selection</h2>
          <div className="mb-4 flex flex-col space-y-2">
            {TOOLS.map((tool) => (
              <button
                key={tool.id}
                className={cn(
                  'toolbar-button flex items-center rounded-md p-3 text-left',
                  isToolActive(tool.id) && 'active',
                  !isToolActive(tool.id) && 'hover:bg-[#CBCACA] dark:hover:bg-[#2F3F66]',
                )}
                onClick={() => handleToolClick(tool)}
              >
                <tool.icon className={cn('mr-3 h-5 w-5', isToolActive(tool.id) && 'text-white')} />
                <span>{tool.name}</span>
              </button>
            ))}
          </div>
          {/* Mobile Account Settings */}
          {isSmallScreen && (
            <div className="mt-4 pt-4 border-t border-[#2F3F66]">
              <AccountSettings />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileToolBar;
