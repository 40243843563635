import * as React from 'react';
import { cn } from '~/utils';

// Style variants
const variants = {
  default: {
    container:
      'relative flex w-full items-center rounded-md border border-[#6A6A6A] bg-[#F2F2F2] dark:border-[#273558] dark:bg-[#1C2846]',
    input:
      'w-full rounded-md border-0 bg-transparent px-4 py-3 pr-16 text-left text-[16px] text-[#6A6A6A] placeholder:leading-[16px] leading-[16px] [appearance:textfield] focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:text-white [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    controlsContainer: 'absolute right-2 flex h-full items-center space-x-1',
    button:
      'text- flex h-6 w-6 items-center justify-center rounded-md text-[#6A6A6A] hover:bg-[#D3D1D1] dark:text-white dark:hover:bg-[#2F3F66]',
    icon: 'h-5 w-5',
  },
};

// Custom InputNumber component with side-by-side buttons
const InputNumber = React.forwardRef<
  HTMLDivElement,
  {
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    value?: number;
    onChange?: (value: number | null) => void;
    controls?: boolean;
    className?: string;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string;
  }
>(
  (
    {
      className,
      controls = true,
      min,
      max,
      step = 1,
      defaultValue,
      value,
      onChange,
      disabled,
      error,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const errorId = React.useId();
    const [internalValue, setInternalValue] = React.useState<number | null>(
      () => {
        if (value !== undefined) {
          return value;
        }
        if (defaultValue !== undefined) {
          return defaultValue;
        }
        return null;
      },
    );

    React.useEffect(() => {
      if (value !== undefined) {
        setInternalValue(value);
      }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setInternalValue(null);
        onChange?.(null);
        return;
      }

      let newValue = Number(e.target.value);

      // Clamp value to min/max range
      if (min !== undefined && newValue < min) {
        newValue = min;
      } else if (max !== undefined && newValue > max) {
        newValue = max;
      }

      setInternalValue(newValue);
      onChange?.(newValue);
    };

    const increment = () => {
      if (disabled) {
        return;
      }

      const newValue = (internalValue || 0) + step;
      if (max !== undefined && newValue > max) {
        return;
      }

      setInternalValue(newValue);
      onChange?.(newValue);
    };

    const decrement = () => {
      if (disabled) {
        return;
      }

      const newValue = (internalValue || 0) - step;
      if (min !== undefined && newValue < min) {
        return;
      }

      setInternalValue(newValue);
      onChange?.(newValue);
    };

    return (
      <div className="relative w-full">
        <div
          className={cn(
            variants.default.container, 
            error && 'border-red-500 dark:border-red-400',
            className ?? '',
          )}
          ref={ref}
        >
          <input
            type="number"
            className={variants.default.input}
            value={internalValue === null ? '' : internalValue}
            onChange={handleChange}
            min={min}
            max={max}
            step={step}
            disabled={disabled}
            aria-invalid={error ? 'true' : 'false'}
            aria-describedby={error && errorMessage ? errorId : undefined}
            {...props}
          />
          {controls && (
            <div className={variants.default.controlsContainer}>
              <button
                type="button"
                className={variants.default.button}
                onClick={increment}
                disabled={
                  disabled || (max !== undefined && (internalValue || 0) >= max)
                }
              >
                <svg
                  className={variants.default.icon}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 15l-7-7-7 7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className={variants.default.button}
                onClick={decrement}
                disabled={
                  disabled || (min !== undefined && (internalValue || 0) <= min)
                }
              >
                <svg
                  className={variants.default.icon}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
        {error && errorMessage && (
          <div
            id={errorId}
            role="alert"
            className="mt-1 text-sm text-red-500 dark:text-red-400"
          >
            Error, {errorMessage}
          </div>
        )}
      </div>
    );
  },
);
InputNumber.displayName = 'Input';

export { InputNumber };
