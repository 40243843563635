import { useRecoilState } from 'recoil';
import { Fragment, useState, memo, useEffect, useRef } from 'react';
import { FileText, LogOut, Users } from 'lucide-react';
import './AccountSettings.css';
import {
  useGetUserBalance,
  useGetStartupConfig,
} from 'librechat-data-provider/react-query';
import { LinkIcon, GearIcon } from '~/components';
import FilesView from '~/components/Chat/Input/Files/FilesView';
import { useAuthContext } from '~/hooks/AuthContext';
import { UserIcon } from '~/components/svg';
import { useLocalize, useMediaQuery } from '~/hooks';
import Settings from './Settings';
import store from '~/store';
import { useNavigate } from 'react-router-dom';
import { cn } from '~/utils';

interface AccountSettingsProps {
  initialOpen?: boolean;
}

function AccountSettings({ initialOpen = false }: AccountSettingsProps) {
  const localize = useLocalize();
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuthContext();
  const { data: startupConfig } = useGetStartupConfig();
  const balanceQuery = useGetUserBalance({
    enabled: !!isAuthenticated && startupConfig?.checkBalance,
  });
  const [showSettings, setShowSettings] = useState(false);
  const [showFiles, setShowFiles] = useRecoilState(store.showFiles);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  // const avatarSrc = useAvatar(user);
  const name = user?.name ?? user?.username ?? localize('com_nav_user');
  
  const [isOpen, setIsOpen] = useState(initialOpen);
  const accordionRef = useRef<HTMLDivElement>(null);

  // Effect to open the accordion when initialOpen changes
  useEffect(() => {
    if (initialOpen) {
      setIsOpen(true);
    }
  }, [initialOpen]);

  return (
    <div className="accordion-container">
      <button
        onClick={() => setIsOpen(!isOpen)}
        aria-label={localize('com_nav_account_settings')}
        data-testid="nav-user"
        className={cn(
          'mt-text-sm flex h-auto w-full items-center gap-2 p-2 text-sm transition-all duration-200 ease-in-out',
          isOpen 
            ? 'bg-[#D3D1D1] dark:bg-[#2F3F66] rounded-t-xl' 
            : 'hover:bg-[#D3D1D1] dark:hover:bg-[#2F3F66] rounded-xl',
        )}
        aria-expanded={isOpen}
        style={{ color: 'var(--text-color)' }}
      >
        <div className="-ml-0.9 -mt-0.8 h-8 w-8 flex-shrink-0">
          <div className="relative flex">
            <div
              className="magenta-gradient-background relative flex items-center justify-center rounded-full text-white"
              style={{
                width: '32px',
                height: '32px',
              }}
              aria-hidden="true"
            >
              {<UserIcon />}
            </div>
          </div>
        </div>
        <div
          className="mt-2 grow overflow-hidden text-ellipsis whitespace-nowrap text-left"
          style={{ marginTop: '0', marginLeft: '0', color: 'var(--text-color)' }}
        >
          {name}
        </div>
      </button>
      <div
        ref={accordionRef}
        className={cn(
          'accordion-menu w-full overflow-hidden transition-all duration-300 ease-in-out rounded-b-xl',
        )}
        style={{
          maxHeight: isOpen ? `${accordionRef.current?.scrollHeight || 500}px` : '0',
          opacity: isOpen ? 1 : 0,
          boxShadow: isOpen ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none',
          background: 'var(--menu-background)',
        }}
      >
        <div className="px-2 pb-2">
          {/* <div
            className="ml-3 mr-2 py-2 text-sm"
            role="note"
            id="gtm-email"
            style={{ color: 'var(--text-color)' }}
          >
            {user?.email ?? localize('com_nav_user')}
          </div> */}
          <div className="h-px w-full my-1 bg-[#435880]"></div>
          {startupConfig?.checkBalance === true &&
            balanceQuery.data != null &&
            !isNaN(parseFloat(balanceQuery.data)) && (
            <>
              <div
                className="ml-3 mr-2 py-2 text-sm"
                role="note"
                style={{ color: 'var(--text-color)' }}
              >
                {`Balance: ${parseFloat(balanceQuery.data).toFixed(2)}`}
              </div>
              <div className="h-px w-full my-1 bg-[#435880]"></div>
            </>
          )}
          <button
            onClick={() => setShowFiles(true)}
            className="select-item text-sm w-full text-left"
          >
            <FileText className="icon-md" aria-hidden="true" />
            {localize('com_nav_my_files')}
          </button>
          {(user?.role === 'USER_MANAGER' || user?.role === 'ADMIN') && (
            <button
              onClick={() => navigate('/whitelist')}
              className="select-item text-sm w-full text-left"
            >
              <Users className="icon-md" aria-hidden="true" />
              Whitelist Management
            </button>
          )}
          {startupConfig?.helpAndFaqURL !== '/' && (
            <button
              onClick={() => navigate('/help-faq')}
              className="select-item text-sm w-full text-left"
            >
              <LinkIcon aria-hidden="true" />
              {localize('com_nav_help_faq')}
            </button>
          )}
          <button
            onClick={() => setShowSettings(true)}
            className="select-item text-sm w-full text-left"
          >
            <GearIcon className="icon-md" aria-hidden="true" />
            {localize('com_nav_settings')}
          </button>
          <div className="h-px w-full my-1 bg-[#435880]"></div>
          <button
            onClick={() => logout()}
            className="select-item text-sm w-full text-left"
          >
            <LogOut className="icon-md" />
            {localize('com_nav_log_out')}
          </button>
        </div>
      </div>
      {showFiles && <FilesView open={showFiles} onOpenChange={setShowFiles} />}
      {showSettings && (
        <Settings open={showSettings} onOpenChange={setShowSettings} />
      )}
    </div>
  );
}

export default memo(AccountSettings);
