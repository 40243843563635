import { memo } from 'react';
import logger from '~/utils/logger';
import { InputWithDropdown, FormField, InlineMessage } from '~/components/ui';

interface Dalle3TabProps {
  width: number;
  height: number;
  quality: string;
  style: string;
  handleWidthChange: (value: number | null) => void;
  handleHeightChange: (value: number | null) => void;
  setQuality: (value: string) => void;
  setStyle: (value: string) => void;
  handleDimensionsChange?: (value: string) => void; // Optional for backward compatibility
  errors?: {
    dimensions?: string;
    quality?: string;
    style?: string;
  };
}

const Dalle3Tab = ({
  width,
  height,
  quality,
  style,
  handleWidthChange,
  handleHeightChange,
  setQuality,
  setStyle,
  handleDimensionsChange,
  errors = {},
}: Dalle3TabProps) => {
  return (
    // Single column layout spanning both grid columns
    <div className="col-span-2 mx-auto max-w-xl space-y-8">
      {/* Dimensions Section */}
      <div className="space-y-4">
        <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
          Dimensions
        </h2>
        <FormField label="Size" tooltip="Choose a size for your image">
          <InputWithDropdown
            options={['1024x1024', '1792x1024', '1024x1792']}
            value={`${width}x${height}`}
            onChange={(e) => {
              logger.log('Dalle3Tab', 'Size changed:', e.target.value);
              if (handleDimensionsChange) {
                // Use the direct dimensions change handler if available
                handleDimensionsChange(e.target.value as string);
              } else {
                // Fallback to the individual width/height handlers
                const [w, h] = (e.target.value as string)
                  .split('x')
                  .map(Number);
                handleWidthChange(w);
                handleHeightChange(h);
              }
            }}
            error={!!errors.dimensions}
            errorMessage={errors.dimensions}
          />
        </FormField>
      </div>

      {/* DALL-E 3 Style Settings */}
      <div className="space-y-4">
        <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
          DALL-E 3 Style
        </h2>
        <FormField
          label="Style"
          tooltip="Choose between vivid or natural style"
        >
          <InputWithDropdown
            options={['vivid', 'natural']}
            value={style}
            onChange={(e) => {
              logger.log('ImageGenerator', 'Style changed:', e.target.value);
              setStyle(e.target.value as string);
            }}
            error={!!errors.style}
            errorMessage={errors.style}
          />
        </FormField>
      </div>

      {/* DALL-E 3 Quality Settings */}
      <div className="space-y-4">
        <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
          Image Quality
        </h2>
        <FormField
          label="Quality"
          tooltip="Choose between standard or HD quality"
        >
          <InputWithDropdown
            options={['standard', 'hd']}
            value={quality}
            onChange={(e) => {
              logger.log('ImageGenerator', 'Quality changed:', e.target.value);
              setQuality(e.target.value as string);
            }}
            error={!!errors.quality}
            errorMessage={errors.quality}
          />
        </FormField>
      </div>
    </div>
  );
};

export default memo(Dalle3Tab);
