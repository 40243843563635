import { useCallback, useEffect, useState, useMemo, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import { PermissionTypes, Permissions } from 'librechat-data-provider';
import type { ConversationListResponse } from 'librechat-data-provider';
import {
  useLocalize,
  useHasAccess,
  useMediaQuery,
  useAuthContext,
  useConversation,
  useLocalStorage,
  useNavScrolling,
  useConversations,
} from '~/hooks';
import { useConversationsInfiniteQuery } from '~/data-provider';
import { Conversations } from '~/components/Conversations';
import BookmarkNav from './Bookmarks/BookmarkNav';
import AccountSettings from './AccountSettings';
import { useSearchContext } from '~/Providers';
import { Spinner, NewChatIcon, Panel } from '~/components/svg';
import SearchBar from './SearchBar';
import NavToggle from './NavToggle';
import NewChat from './NewChat';
import { cn } from '~/utils';
import store from '~/store';
import { PanelRightOpen } from 'lucide-react';

const Nav = ({
  navVisible,
  setNavVisible,
  toolBarVisible = false,
  isToolBarCollapsed = true,
}: {
  navVisible: boolean;
  setNavVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toolBarVisible?: boolean;
  isToolBarCollapsed?: boolean;
}) => {
  const localize = useLocalize();
  const navigate = useNavigate();
  const { conversationId } = useParams();
  const { isAuthenticated } = useAuthContext();

  const [navWidth, setNavWidth] = useState('260px');
  const [isHovering, setIsHovering] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [newUser, setNewUser] = useLocalStorage('newUser', true);
  const [isToggleHovering, setIsToggleHovering] = useState(false);

  const hasAccessToBookmarks = useHasAccess({
    permissionType: PermissionTypes.BOOKMARKS,
    permission: Permissions.USE,
  });

  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const toggleNavVisible = () => {
    setNavVisible((prev: boolean) => {
      localStorage.setItem('navVisible', JSON.stringify(!prev));
      return !prev;
    });
    if (newUser) {
      setNewUser(false);
    }
  };

  useEffect(() => {
    if (isSmallScreen) {
      const savedNavVisible = localStorage.getItem('navVisible');
      if (savedNavVisible === null) {
        toggleNavVisible();
      }
      setNavWidth('320px');
    } else {
      setNavWidth('260px');
    }
  }, [isSmallScreen, toggleNavVisible]);

  const { newConversation } = useConversation();
  const [showLoading, setShowLoading] = useState(false);
  const isSearchEnabled = useRecoilValue(store.isSearchEnabled);

  const { refreshConversations } = useConversations();
  const { pageNumber, searchQuery, setPageNumber, searchQueryRes } = useSearchContext();
  const [tags, setTags] = useState<string[]>([]);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useConversationsInfiniteQuery(
      {
        pageNumber: pageNumber.toString(),
        isArchived: false,
        tags: tags.length === 0 ? undefined : tags,
      },
      { enabled: isAuthenticated },
    );
  useEffect(() => {
    // When a tag is selected, refetch the list of conversations related to that tag
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);
  const { containerRef, moveToTop } = useNavScrolling<ConversationListResponse>({
    setShowLoading,
    hasNextPage: searchQuery ? searchQueryRes?.hasNextPage : hasNextPage,
    fetchNextPage: searchQuery ? searchQueryRes?.fetchNextPage : fetchNextPage,
    isFetchingNextPage: searchQuery
      ? searchQueryRes?.isFetchingNextPage ?? false
      : isFetchingNextPage,
  });

  const conversations = useMemo(
    () =>
      (searchQuery ? searchQueryRes?.data : data)?.pages.flatMap((page) => page.conversations) ||
      [],
    [data, searchQuery, searchQueryRes?.data],
  );

  const clearSearch = () => {
    setPageNumber(1);
    refreshConversations();
    if (conversationId == 'search') {
      newConversation();
    }
  };

  // Function to determine the position of the nav toggle based on visibility states
  const getNavTogglePosition = (
    navVisible: boolean,
    toolBarVisible: boolean,
    isToolBarCollapsed: boolean,
  ) => {
    if (navVisible) {
      if (toolBarVisible) {
        return isToolBarCollapsed
          ? 'left-[324px]' // 260px (nav width) + 64px (collapsed toolbar)
          : 'left-[495px]'; // 260px (nav width) + 235px (expanded toolbar)
      }
      return 'left-[260px]'; // Just the nav width when toolbar is not visible
    } else {
      if (toolBarVisible) {
        return isToolBarCollapsed
          ? 'left-[64px]' // Just the collapsed toolbar width
          : 'left-[235px]'; // Just the expanded toolbar width
      }
      return 'left-0'; // No offset when both nav and toolbar are hidden/collapsed
    }
  };

  const itemToggleNav = () => {
    if (isSmallScreen) {
      toggleNavVisible();
    }
  };

  return (
    <>
      <div className='relative'>
        <div
          data-testid='nav'
          className={
            'nav active hidden max-w-[320px] flex-shrink-0 overflow-x-hidden bg-[#F2F2F2] dark:bg-[#060B17] md:block md:max-w-[260px]'
          }
          style={{
            width: navVisible ? navWidth : '0px',
            visibility: navVisible ? 'visible' : 'hidden',
            transition: 'width 0.2s, visibility 0.2s',
          }}
        >
          <div className='h-full w-[320px] md:w-[260px]'>
            <div className='flex h-full min-h-0 flex-col'>
              <div
                className={cn(
                  'flex h-full min-h-0 flex-col transition-opacity',
                  isToggleHovering && !isSmallScreen ? 'opacity-50' : 'opacity-100',
                )}
              >
                <div
                  className={cn(
                    'scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20',
                  )}
                >
                  <nav
                    id='chat-history-nav'
                    aria-label={localize('com_ui_chat_history')}
                    className='relative flex h-full w-full flex-col px-3 pb-11'
                  >
                    <div className='flex h-full flex-col'>
                      {/* Scrollable container */}
                      <div
                        className={cn(
                          '-mr-2 flex-1 flex-col overflow-y-auto pr-2 transition-opacity duration-500',
                          isHovering ? '' : 'scrollbar-transparent',
                        )}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={containerRef}
                      >
                        <NewChat
                          toggleNav={itemToggleNav}
                          isSmallScreen={isSmallScreen}
                          subHeaders={
                            <>
                              {isSearchEnabled === true && (
                                <SearchBar
                                  clearSearch={clearSearch}
                                  isSmallScreen={isSmallScreen}
                                />
                              )}
                              {hasAccessToBookmarks === true && (
                                <>
                                  <div className='mt-1.5' />
                                  <BookmarkNav
                                    tags={tags}
                                    setTags={setTags}
                                    isSmallScreen={isSmallScreen}
                                  />
                                </>
                              )}
                            </>
                          }
                        />

                        <Conversations
                          conversations={conversations}
                          moveToTop={moveToTop}
                          toggleNav={itemToggleNav}
                        />
                        {(isFetchingNextPage || showLoading) && (
                          <Spinner className={cn('m-1 mx-auto mb-4 h-4 w-4 text-text-primary')} />
                        )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NavToggle
          isHovering={isToggleHovering}
          setIsHovering={setIsToggleHovering}
          onToggle={toggleNavVisible}
          navVisible={navVisible}
          className='absolute bottom-0 left-4 z-[100] hidden transition-transform duration-200 md:flex'
          description={navVisible ? 'Collapse Conversations' : 'Expand Conversations'}
          icon={PanelRightOpen}
          translateX={true}
          translateDirection='right'
          side='right'
          parentComponent='nav'
        />
      </div>
      {isSmallScreen && (
        <div
          id='mobile-nav-mask-toggle'
          role='button'
          tabIndex={0}
          className={`nav-mask ${navVisible ? 'active' : ''}`}
          onClick={toggleNavVisible}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleNavVisible();
            }
          }}
          aria-label='Toggle navigation'
        />
      )}
    </>
  );
};

export default memo(Nav);
