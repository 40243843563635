import { z } from 'zod';

// Individual field validators for real-time validation
export const validators = {
  // Common validators
  prompt: z.string().min(1, 'Prompt is required'),
  
  // Flux validators
  width: z
    .number()
    .int()
    .min(256, 'Width must be at least 256px')
    .max(1440, 'Width must be at most 1440px')
    .refine((val) => val % 32 === 0, {
      message: 'Width must be a multiple of 32',
    }),
  
  height: z
    .number()
    .int()
    .min(256, 'Height must be at least 256px')
    .max(1440, 'Height must be at most 1440px')
    .refine((val) => val % 32 === 0, {
      message: 'Height must be a multiple of 32',
    }),
  
  aspect_ratio: z.enum([
    'custom',
    '1:1',
    '16:9',
    '3:2',
    '2:3',
    '4:5',
    '5:4',
    '9:16',
    '3:4',
    '4:3',
  ]),
  
  output_format: z.enum(['webp', 'jpg', 'png']),
  
  output_quality: z
    .number()
    .int()
    .min(0, 'Quality must be at least 0')
    .max(100, 'Quality must be at most 100'),
  
  safety_tolerance: z
    .number()
    .int()
    .min(1, 'Safety tolerance must be at least 1')
    .max(6, 'Safety tolerance must be at most 6'),
  
  seed: z.union([
    z.number().int(),
    z.string().transform((val) => {
      const parsed = parseInt(val);
      return isNaN(parsed) ? undefined : parsed;
    }),
  ]),
  
  // DALL-E 3 validators
  dalle3_size: z.enum(['1024x1024', '1792x1024', '1024x1792'], {
    errorMap: () => ({
      message: 'Size must be one of: 1024x1024, 1792x1024, or 1024x1792',
    }),
  }),
  
  dalle3_quality: z.enum(['standard', 'hd']),
  
  dalle3_style: z.enum(['vivid', 'natural']),
};

// Helper function to validate a field and return the error message if invalid
export const validateField = <T>(
  schema: z.ZodType<T>,
  value: unknown,
): string | undefined => {
  const result = schema.safeParse(value);
  if (!result.success) {
    return result.error.errors[0]?.message;
  }
  return undefined;
};

// Flux Schema based on the provided JSON schema
export const fluxSchema = z.object({
  prompt: validators.prompt,
  seed: validators.seed.optional(),
  width: validators.width,
  height: validators.height,
  aspect_ratio: validators.aspect_ratio.default('custom'),
  image_prompt: z.string().optional(),
  output_format: validators.output_format.default('webp'),
  output_quality: validators.output_quality.default(80),
  safety_tolerance: validators.safety_tolerance.default(2),
  prompt_upsampling: z.boolean().default(false),
  stylePreset: z.string().optional(),
});

// DALL-E 3 Schema based on the provided curl example
export const dalle3Schema = z.object({
  model: z.literal('dall-e-3'),
  prompt: validators.prompt,
  n: z.number().int().min(1).max(1).default(1),
  size: validators.dalle3_size,
  quality: validators.dalle3_quality.optional(),
  style: validators.dalle3_style.optional(),
});

// Type definitions derived from the schemas
export type FluxFormData = z.infer<typeof fluxSchema>;
export type Dalle3FormData = z.infer<typeof dalle3Schema>;
