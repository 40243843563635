import { useCallback, useState, memo, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalize } from '~/hooks';
import { cn } from '~/utils';
import { TooltipAnchor, Button } from '~/components';
import NavToggle from '~/components/Nav/NavToggle';
import AccountSettings from '~/components/Nav/AccountSettings';
import { UserIcon } from '~/components/svg';
import { logger } from '~/utils';
import { PanelRightOpen } from 'lucide-react';
import type { ToolItem } from '~/components/ToolBar/types';
import '~/routes/Tools/tools.css'; // Import the CSS file with button-gradient class

interface ToolBarProps {
  tools: ToolItem[];
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}

const ToolBar = ({ tools, isCollapsed, setIsCollapsed }: ToolBarProps) => {
  const localize = useLocalize();
  const navigate = useNavigate();
  // Use useLocation to get the current location (path and query params)
  const location = useLocation();
  
  // State to track the last active tool ID
  const [lastActiveToolId, setLastActiveToolId] = useState(() => {
    // Initialize from localStorage if available
    if (typeof window !== 'undefined') {
      const storedToolId = localStorage.getItem('activeToolId');
      return storedToolId || '';
    }
    return '';
  });

  // Function to determine if a tool is active based on the current path
  const isToolActive = useCallback((toolId: string) => {
    const pathname = location.pathname;
    
    // Check if the image generator tool is active
    const isImageGeneratorActive = pathname.includes('/tools/image-generator');
    
    let isActive = false;
    
    // For the AI Chats tool (home button), it should be active by default
    // unless another specific tool path matches
    if (toolId === '/c/new') {
      isActive = !isImageGeneratorActive;
    }
    
    // For the Image Generator tool
    else if (toolId === '/c/tools/image-generator') {
      isActive = isImageGeneratorActive;
    }
    
    // For any other tools that might be added in the future
    else {
      isActive = pathname.startsWith(toolId);
    }
    
    // If this tool is active, update the lastActiveToolId
    if (isActive && lastActiveToolId !== toolId) {
      setLastActiveToolId(toolId);
      localStorage.setItem('activeToolId', toolId);
    }
    
    // During navigation or when no tool matches the current path,
    // maintain the active state for the last active tool
    // This prevents the flash of white during route transitions
    const anyToolActive = tools.some(t => {
      if (t.id === '/c/new') {
        return !isImageGeneratorActive;
      } else if (t.id === '/c/tools/image-generator') {
        return isImageGeneratorActive;
      } else {
        return pathname.startsWith(t.id);
      }
    });
    
    return isActive || (lastActiveToolId === toolId && !anyToolActive);
  }, [location, lastActiveToolId]);
  const [isHovering, setIsHovering] = useState(false);
  const [shouldOpenAccountSettings, setShouldOpenAccountSettings] = useState(false);

  const handleToolClick = useCallback(
    (tool: ToolItem) => {
      logger.log('ToolBar', 'Navigating to tool:', tool.id);
      
      // Immediately update the active tool ID before navigation
      setLastActiveToolId(tool.id);
      localStorage.setItem('activeToolId', tool.id);
      
      // Navigate directly to the tool's route
      navigate(tool.id);
    },
    [navigate, setLastActiveToolId],
  );

  const toggleToolBarVisible = useCallback(
    (fromAccountSettings = false) => {
      const newValue = !isCollapsed;
      setIsCollapsed(newValue);
      localStorage.setItem('toolBarCollapsed', JSON.stringify(newValue));

      // Only open account settings when expanding the toolbar from the account icon
      if (fromAccountSettings && isCollapsed) {
        setShouldOpenAccountSettings(true);
      } else {
        // Always reset the flag when toggling from other sources
        setShouldOpenAccountSettings(false);
      }
    },
    [isCollapsed, setIsCollapsed],
  );

  // Reset the flag when toolbar state changes
  useEffect(() => {
    // When toolbar collapses, always reset the flag
    if (isCollapsed) {
      setShouldOpenAccountSettings(false);
    }
  }, [isCollapsed]);

  return (
    <>
      <div
        data-testid='toolbar'
        className={cn(
          'relative flex-shrink-0 overflow-x-hidden border-r-[5px] border-[#CBCACA] bg-[#FFFFFF] dark:border-[#2F3F66] dark:bg-[#0F172A]',
          isCollapsed ? 'w-[64px]' : 'w-[235px]',
        )}
        style={{
          transition: 'width 0.2s',
        }}
      >
        <div className='flex h-full min-h-0 flex-col'>
          <div className='flex h-full min-h-0 flex-col opacity-100 transition-opacity'>
            <div className='relative h-full w-full flex-1 items-start border-white/20'>
              <nav
                aria-label={localize('com_ui_tools')}
                className='flex h-full w-full flex-col px-2 pb-4 pt-2'
              >
                {/* Account Settings at the top */}
                <div className={isCollapsed ? 'px-1' : ''}>
                  {isCollapsed ? (
                    <div className='flex justify-center mb-4'>
                      <TooltipAnchor
                        description={localize('com_nav_account_settings')}
                        side='right'
                        render={
                          <button
                            className='magenta-gradient-background relative flex h-8 w-8 items-center justify-center rounded-full text-white transition-opacity hover:opacity-80'
                            onClick={() => toggleToolBarVisible(true)}
                            aria-label={localize('com_nav_account_settings')}
                          >
                            <UserIcon width={20} height={20} />
                          </button>
                        }
                      />
                    </div>
                  ) : (
                    <div className='toolbar-account-settings'>
                      <AccountSettings initialOpen={shouldOpenAccountSettings} />
                    </div>
                  )}
                </div>

                <div className='flex flex-col space-y-4 mt-4 transition-all duration-300'>
                  {tools.map((tool) =>
                    isCollapsed ? (
                      <TooltipAnchor
                        key={tool.id}
                        description={tool.name}
                        side='right'
                        render={
                          <Button
                            key={tool.id}
                            variant={isToolActive(tool.id) ? 'default' : 'ghost'}
                            className={cn(
                              'justify-center font-normal text-black hover:bg-[#CBCACA] dark:text-white dark:hover:bg-[#2F3F66] toolbar-button',
                              isToolActive(tool.id) && 'active',
                            )}
                            size="icon"
                            onClick={() => handleToolClick(tool)}
                          >
                            <tool.icon className={cn('h-5 w-5', isToolActive(tool.id) && 'text-white')} />
                            <span className="sr-only">{tool.name}</span>
                          </Button>
                        }
                      />
                    ) : (
                      <Button
                        key={tool.id}
                        variant={isToolActive(tool.id) ? 'default' : 'ghost'}
                        className={cn(
                          'justify-start font-normal text-black hover:bg-[#CBCACA] dark:text-white dark:hover:bg-[#2F3F66] toolbar-button',
                          isToolActive(tool.id) && 'active',
                        )}
                        onClick={() => handleToolClick(tool)}
                      >
                        <tool.icon className={cn('mr-2 h-5 w-5', isToolActive(tool.id) && 'text-white')} />
                        {tool.name}
                      </Button>
                    ),
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <NavToggle
        isHovering={isHovering}
        setIsHovering={setIsHovering}
        onToggle={() => toggleToolBarVisible(false)}
        navVisible={!isCollapsed}
        className='absolute bottom-0 left-4 z-40 hidden h-6 md:flex'
        side='left'
        translateX={true}
        translateDirection='right'
        icon={PanelRightOpen}
        description={isCollapsed ? 'Expand Toolbar' : 'Collapse Toolbar'}
        parentComponent='toolbar'
      />
    </>
  );
};

export default memo(ToolBar);
