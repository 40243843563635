import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { QuestionMark } from './QuestionMark';
import { TooltipAnchor } from './Tooltip';
import { cn } from '../../utils';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    title?: string;
    tooltip?: string;
  }
>(({ className = '', title, tooltip, ...props }, ref) => (
  <div className="flex items-center gap-2.5">
    <SwitchPrimitives.Root
      className={cn(
        'focus-visible:ring-ring focus-visible:ring-offset-background peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#6A6A6A] data-[state=unchecked]:bg-[#CBCACA] dark:data-[state=checked]:bg-[#F214A1] dark:data-[state=unchecked]:bg-[#909BB5]',
        className,
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
        )}
      />
    </SwitchPrimitives.Root>

    {title ? (
      <span className="mb-1 text-[20px] font-medium text-[#0F172A] dark:text-white">
        {title}
      </span>
    ) : null}

    {tooltip ? (
      <TooltipAnchor description={tooltip}>
        <QuestionMark />
      </TooltipAnchor>
    ) : null}
  </div>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
