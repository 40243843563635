import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import ToolBar from '~/components/ToolBar';
import { TOOLS } from '~/components/ToolBar/ToolsConfig';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import { useUserTermsQuery } from '~/data-provider';

import type { ContextType } from '~/common';
import { AgentsMapContext, AssistantsMapContext, FileMapContext, SearchContext } from '~/Providers';
import {
  useAuthContext,
  useAssistantsMap,
  useAgentsMap,
  useFileMap,
  useSearch,
  useMediaQuery,
} from '~/hooks';
import { Nav, MobileNav } from '~/components/Nav';
import TermsAndConditionsModal from '~/components/ui/TermsAndConditionsModal';
import { Banner } from '~/components/Banners';

export default function Root() {
  const { isAuthenticated, logout } = useAuthContext();
  const navigate = useNavigate();
  const [navVisible, setNavVisible] = useState(() => {
    const savedNavVisible = localStorage.getItem('navVisible');
    return savedNavVisible !== null ? JSON.parse(savedNavVisible) : true;
  });
  const [isToolBarCollapsed, setIsToolBarCollapsed] = useState(() => {
    const savedState = localStorage.getItem('toolBarCollapsed');
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  // Initialize toolbar as collapsed by default
  useEffect(() => {
    if (localStorage.getItem('toolBarCollapsed') === null) {
      localStorage.setItem('toolBarCollapsed', 'true');
    }
  }, []);
  const [bannerHeight, setBannerHeight] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const location = useLocation();

  // Check if the current route is a tool route
  const isToolRoute = location.pathname.startsWith('/c/tools/');

  const search = useSearch({ isAuthenticated });
  const fileMap = useFileMap({ isAuthenticated });
  const assistantsMap = useAssistantsMap({ isAuthenticated });
  const agentsMap = useAgentsMap({ isAuthenticated });

  const [showTerms, setShowTerms] = useState(false);
  const { data: config } = useGetStartupConfig();
  const { data: termsData } = useUserTermsQuery({
    enabled: isAuthenticated && config?.interface?.termsOfService?.modalAcceptance === true,
  });

  useEffect(() => {
    if (termsData) {
      setShowTerms(!termsData.termsAccepted);
    }
  }, [termsData]);

  const handleAcceptTerms = () => {
    setShowTerms(false);
  };

  const handleDeclineTerms = () => {
    setShowTerms(false);
    logout();
    navigate('/login');
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <SearchContext.Provider value={search}>
      <FileMapContext.Provider value={fileMap}>
        <AssistantsMapContext.Provider value={assistantsMap}>
          <AgentsMapContext.Provider value={agentsMap}>
            <Banner onHeightChange={setBannerHeight} />
            <div className='flex' style={{ height: `calc(100dvh - ${bannerHeight}px)` }}>
              <div className='relative z-0 flex h-full w-full overflow-hidden'>
                {/* Only render the desktop ToolBar on larger screens */}
                {!isSmallScreen && (
                  <ToolBar
                    tools={TOOLS}
                    isCollapsed={isToolBarCollapsed}
                    setIsCollapsed={setIsToolBarCollapsed}
                  />
                )}
                {/* Only render Nav (chat history) if not on a tool route */}
                {!isToolRoute && (
                  <Nav
                    navVisible={navVisible}
                    setNavVisible={setNavVisible}
                    toolBarVisible={!isSmallScreen}
                    isToolBarCollapsed={isToolBarCollapsed}
                  />
                )}
                <div className='relative flex h-full max-w-full flex-1 flex-col overflow-hidden'>
                  <MobileNav />
                  <Outlet context={{ navVisible, setNavVisible } satisfies ContextType} />
                </div>
              </div>
            </div>
          </AgentsMapContext.Provider>
          {config?.interface?.termsOfService?.modalAcceptance === true && (
            <TermsAndConditionsModal
              open={showTerms}
              onOpenChange={setShowTerms}
              onAccept={handleAcceptTerms}
              onDecline={handleDeclineTerms}
              title={config.interface.termsOfService.modalTitle}
              modalContent={config.interface.termsOfService.modalContent}
            />
          )}
        </AssistantsMapContext.Provider>
      </FileMapContext.Provider>
    </SearchContext.Provider>
  );
}
