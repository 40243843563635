import { useLocalize } from '~/hooks';
import { TooltipAnchor } from '~/components/ui';
import { cn } from '~/utils';

export default function NavToggle({
  onToggle,
  navVisible,
  isHovering,
  setIsHovering,
  side = 'left',
  className = '',
  translateX = true,
  translateDirection = 'right',
  icon: Icon = null,
  description,
  parentComponent = 'toolbar',
}: {
  onToggle: () => void;
  navVisible: boolean;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
  side?: 'left' | 'right';
  className?: string;
  translateX?: boolean;
  translateDirection?: 'left' | 'right';
  icon?: React.ComponentType<{ className?: string }> | null;
  description?: string | undefined;
  parentComponent?: 'nav' | 'toolbar';
}) {
  const localize = useLocalize();
  const transition = {
    transition: 'transform 0.3s ease, opacity 0.2s ease',
  };

  const rotationDegree = 15;
  const rotation = isHovering || !navVisible ? `${rotationDegree}deg` : '0deg';
  const topBarRotation = side === 'right' ? `-${rotation}` : rotation;
  const bottomBarRotation = side === 'right' ? rotation : `-${rotation}`;

  // Determine the translation class based on visibility and direction
  const getTranslateClass = () => {
    if (!navVisible || !translateX) {
      return 'translate-x-0';
    }

    // Apply different translation amounts based on parent component
    if (parentComponent === 'nav') {
      return translateDirection === 'right' ? 'translate-x-[200px]' : '-translate-x-[200px]';
    } else if (parentComponent === 'toolbar') {
      return translateDirection === 'right' ? 'translate-x-[175px]' : '-translate-x-[175px]';
    }

    return 'translate-x-0';
  };

  return (
    <div
      className={cn(
        className,
        '-translate-y-1/2 transition-transform',
        navVisible ? 'rotate-0' : 'rotate-180',
        getTranslateClass(),
      )}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <TooltipAnchor
        side={side === 'right' ? 'left' : 'right'}
        aria-label={side === 'left' ? localize('com_ui_chat_history') : localize('com_ui_controls')}
        aria-expanded={navVisible}
        aria-controls={side === 'left' ? 'chat-history-nav' : 'controls-nav'}
        id={`toggle-${side}-nav`}
        onClick={onToggle}
        role='button'
        description={description || ''}
        className='flex items-center justify-center'
        tabIndex={0}
      >
        <span className='' data-state='closed'>
          <div
            className='flex items-center justify-center'
            style={{ ...transition, opacity: isHovering ? 1 : 0.5 }}
          >
            {Icon ? (
              // Render custom icon if provided
              <Icon className='h-6 w-6 text-black dark:text-white' />
            ) : (
              // Otherwise render default bars
              <div className='flex h-12 w-12 flex-col items-center'>
                {/* Top bar */}
                <div
                  className='h-5 w-1 rounded-full bg-black dark:bg-white'
                  style={{
                    ...transition,
                    transform: `translateY(0.15rem) rotate(${topBarRotation}) translateZ(0px)`,
                  }}
                />
                {/* Bottom bar */}
                <div
                  className='h-5 w-1 rounded-full bg-black dark:bg-white'
                  style={{
                    ...transition,
                    transform: `translateY(-0.15rem) rotate(${bottomBarRotation}) translateZ(0px)`,
                  }}
                />
              </div>
            )}
          </div>
        </span>
      </TooltipAnchor>
    </div>
  );
}
