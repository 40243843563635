import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '~/utils';

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    onDoubleClick?: () => void;
    title?: string;
    showPercentage?: boolean;
  }
>(
  (
    { className, onDoubleClick, title, showPercentage = false, ...props },
    ref,
  ) => (
    <div className="w-full space-y-2.5">
      {title ? <div className="text-primary text-[16px]">{title}</div> : null}
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          'relative flex w-full touch-none select-none items-center',
          className,
        )}
        onDoubleClick={onDoubleClick}
        {...props}
      >
        <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-[#CBCACA] dark:bg-[#F1F5F9]">
          <SliderPrimitive.Range className="absolute h-full bg-[#6A6A6A] dark:bg-[#2F3F66]" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="ring-offset-background focus-visible:ring-ring block h-5 w-5 rounded-full border-2 border-[#FFF] bg-[#6A6A6A] transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:border-[#0F172A] dark:bg-[#FFF]" />
      </SliderPrimitive.Root>
      {showPercentage ? (
        <div className="text-primary flex justify-between text-[12px]">
          <span className="">0%</span>
          <span className="">100%</span>
        </div>
      ) : null}
    </div>
  ),
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
