import { Home, Image } from 'lucide-react';
import type { ToolItem } from './types';

// Define the available tools
export const TOOLS: ToolItem[] = [
  {
    id: '/c/new',
    name: 'Ayzenberg AI',
    icon: Home,
    description: 'Home',
  },
  {
    id: '/c/tools/image-generator',
    name: 'Image Generator',
    icon: Image,
    description: 'Generate images using Flux or Dalle-3',
  },
];

// Get a tool by ID
export const getToolById = (id: string): ToolItem | undefined => {
  return TOOLS.find((tool) => tool.id === id);
};
