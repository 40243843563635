import * as React from 'react';
import { QuestionMark } from './QuestionMark';
import { TooltipAnchor } from './Tooltip';
import { cn } from '~/utils';

interface FormFieldProps {
  children: React.ReactNode;
  label?: string;
  tooltip?: string;
  className?: string;
  labelClassName?: string;
}

const FormField = ({ children, label, tooltip, className, labelClassName }: FormFieldProps) => {
  return (
    <div className={cn('w-full space-y-2.5', className)}>
      {label ? (
        <div className="flex items-center justify-between">
          <span className={cn('text-[16px] font-normal leading-[28px] tracking-[0.16px] text-[#0F172A] dark:text-white', labelClassName)}>
            {label}
          </span>
          {tooltip ? (
            <TooltipAnchor description={tooltip}>
              <QuestionMark />
            </TooltipAnchor>
          ) : null}
        </div>
      ) : null}
      <div className="relative w-full">{children}</div>
    </div>
  );
};

export { FormField };
