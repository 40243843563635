import { memo } from 'react';
import logger from '~/utils/logger';
import {
  Slider,
  Switch,
  InputWithDropdown,
  InputNumber,
  FormField,
  InlineMessage,
} from '~/components/ui';

interface FluxTabProps {
  width: number;
  height: number;
  sizePreset: string;
  stylePreset: string;
  outputFormat: string;
  safetyTolerance: number;
  quality: number;
  promptUpsampling: boolean;
  randomSeed: string;
  handleWidthChange: (value: number | null) => void;
  handleHeightChange: (value: number | null) => void;
  handleSizePresetChange: (value: string) => void;
  handleStylePresetChange: (value: string) => void;
  handleOutputFormatChange: (value: string) => void;
  handleSafetyToleranceChange: (value: number | null) => void;
  handleQualityChange: (value: number) => void;
  handlePromptUpsamplingChange: (checked: boolean) => void;
  handleRandomSeedChange: (value: string) => void;
  errors?: {
    width?: string;
    height?: string;
    sizePreset?: string;
    stylePreset?: string;
    outputFormat?: string;
    safetyTolerance?: string;
    quality?: string;
    randomSeed?: string;
  };
}

const FluxTab = ({
  width,
  height,
  sizePreset,
  stylePreset,
  outputFormat,
  safetyTolerance,
  quality,
  promptUpsampling,
  randomSeed,
  handleWidthChange,
  handleHeightChange,
  handleSizePresetChange,
  handleStylePresetChange,
  handleOutputFormatChange,
  handleSafetyToleranceChange,
  handleQualityChange,
  handlePromptUpsamplingChange,
  handleRandomSeedChange,
  errors = {},
}: FluxTabProps) => {
  return (
    <>
      {/* Left Column */}
      <div className="space-y-8">
        {/* Dimensions Section */}
        <div className="space-y-4">
          <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
            Dimensions
          </h2>
          <FormField label="Aspect Ratio" tooltip="Choose an aspect ratio">
            <InputWithDropdown
              options={[
                'custom',
                '1:1',
                '16:9',
                '3:2',
                '2:3',
                '4:5',
                '5:4',
                '9:16',
                '3:4',
                '4:3',
              ]}
              value={sizePreset}
              onChange={(e) => handleSizePresetChange(e.target.value as string)}
              readOnly={true}
            />
          </FormField>
          <FormField
            label="Width (px)"
            tooltip="Width in pixels (custom aspect ratio only, multiple of 32)"
          >
            <InputNumber
              min={256}
              max={1440}
              step={64}
              value={width}
              onChange={handleWidthChange}
              disabled={sizePreset !== 'custom'}
              error={!!errors.width}
              errorMessage={errors.width}
            />
          </FormField>
          <FormField
            label="Height (px)"
            tooltip="Height in pixels (custom aspect ratio only, multiple of 32)"
          >
            <InputNumber
              min={256}
              max={1440}
              step={64}
              value={height}
              onChange={handleHeightChange}
              disabled={sizePreset !== 'custom'}
              error={!!errors.height}
              errorMessage={errors.height}
            />
          </FormField>
        </div>

        {/* Style Section */}
        <div className="space-y-6">
          <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
            Style
          </h2>
          <FormField
            label="Style Preset"
            tooltip="Choose a style for your image. This will be prepended to the prompt."
          >
            <InputWithDropdown
              options={[
                'Photorealistic',
                'Digital Art',
                'Anime',
                'Cinematic',
                'Fantasy',
              ]}
              value={stylePreset}
              onChange={(e) =>
                handleStylePresetChange(e.target.value as string)
              }
              error={!!errors.stylePreset}
              errorMessage={errors.stylePreset}
            />
          </FormField>
          <InlineMessage>
            Style presets will be added to your prompt automatically
          </InlineMessage>
        </div>
      </div>

      {/* Right Column */}
      <div className="space-y-8">
        {/* Output Settings */}
        <div className="space-y-6 md:space-y-4">
          <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
            Output Settings
          </h2>
          <FormField label="Output Format" tooltip="Choose the image format">
            <InputWithDropdown
              options={['webp', 'png', 'jpg']}
              value={outputFormat}
              onChange={(e) =>
                handleOutputFormatChange(e.target.value as string)
              }
              readOnly={true}
              error={!!errors.outputFormat}
              errorMessage={errors.outputFormat}
            />
          </FormField>
          <FormField
            label="Safety Tolerance"
            tooltip="Safety tolerance, 1 is most strict and 6 is most permissive"
          >
            <InputNumber
              min={1}
              max={6}
              step={1}
              value={safetyTolerance}
              onChange={handleSafetyToleranceChange}
              error={!!errors.safetyTolerance}
              errorMessage={errors.safetyTolerance}
            />
          </FormField>
          <FormField
            label="Output Quality"
            tooltip="Quality when saving the output images (0-100)"
          >
            <Slider
              showPercentage={true}
              value={[quality]}
              onValueChange={(values) => handleQualityChange(values[0])}
              min={0}
              max={100}
              step={1}
            />
          </FormField>
        </div>

        {/* Advanced Settings */}
        <div className="space-y-6 md:space-y-4">
          <h2 className="text-[26px] font-bold leading-[32px] tracking-[-0.156px] text-[#0F172A] dark:text-white">
            Advanced Settings
          </h2>
          <div className="flex items-center">
            <Switch
              checked={promptUpsampling}
              onCheckedChange={handlePromptUpsamplingChange}
              title="Prompt Upsampling"
              tooltip="Enhance prompt with AI"
            />
          </div>
          <FormField
            label="Random Seed"
            tooltip="Set a seed for reproducible results, this can be any number"
          >
            <InputWithDropdown
              options={['-1', '0', '42', '1337', '2023']}
              value={randomSeed}
              onChange={(e) => handleRandomSeedChange(e.target.value as string)}
              error={!!errors.randomSeed}
              errorMessage={errors.randomSeed}
            />
          </FormField>
        </div>
      </div>
    </>
  );
};

export default memo(FluxTab);
