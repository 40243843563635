import { useMemo, memo } from 'react';
import { parseISO, isToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { TConversation } from 'librechat-data-provider';
import { groupConversationsByDate } from '~/utils';
import { useLocalize, useConversation, useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import Convo from './Convo';

const Conversations = ({
  conversations,
  moveToTop,
  toggleNav,
}: {
  conversations: Array<TConversation | null>;
  moveToTop: () => void;
  toggleNav: () => void;
}) => {
  const navigate = useNavigate();
  const localize = useLocalize();
  const { newConversation } = useConversation();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const groupedConversations = useMemo(
    () => groupConversationsByDate(conversations),
    [conversations],
  );
  const firstTodayConvoId = useMemo(
    () =>
      conversations.find((convo) => convo && convo.updatedAt && isToday(parseISO(convo.updatedAt)))
        ?.conversationId,
    [conversations],
  );

  return (
    <div className={cn(
      'text-token-text-primary flex flex-col gap-2 text-sm relative pb-2',
    )}>
      <div>
        <span>
          {groupedConversations.map(([groupName, convos]) => (
            <div key={groupName}>
              <div
                className="text-[#0F172A] dark:text-white text-xs font-normal leading-normal"
                style={{
                  marginTop: '20px',
                  marginBottom: '5px',
                  paddingLeft: '10px',
                }}
              >
                {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                {localize(groupName) || groupName}
              </div>
              {convos.map((convo, i) => (
                <Convo
                  key={`${groupName}-${convo.conversationId}-${i}`}
                  isLatestConvo={convo.conversationId === firstTodayConvoId}
                  conversation={convo}
                  retainView={moveToTop}
                  toggleNav={toggleNav}
                />
              ))}
              <div
                style={{
                  marginTop: '5px',
                  marginBottom: '5px',
                }}
              />
            </div>
          ))}
        </span>
      </div>
    </div>
  );
};

export default memo(Conversations);
