import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { useCallback, memo, ReactNode, useEffect, useRef } from 'react';
import { useGetEndpointsQuery } from 'librechat-data-provider/react-query';
import type { TResPlugin, TInput } from 'librechat-data-provider';
import { ChevronDownIcon, LucideProps } from 'lucide-react';
import { useShareContext } from '~/Providers';
import { cn, formatJSON, formatToolName } from '~/utils';
import { trackToolUsage } from '~/utils/gtm';
import { Spinner } from '~/components';
import CodeBlock from './CodeBlock';

type PluginIconProps = LucideProps & {
  className?: string;
};

function formatInputs(inputs: TInput[]) {
  let output = '';

  for (let i = 0; i < inputs.length; i++) {
    const input = formatJSON(`${inputs[i]?.inputStr ?? inputs[i]}`);
    output += input;

    if (inputs.length > 1 && i !== inputs.length - 1) {
      output += ',\n';
    }
  }

  return output;
}

type PluginProps = {
  plugin: TResPlugin;
};

const Plugin: React.FC<PluginProps> = ({ plugin }) => {
  const { isSharedConvo } = useShareContext();
  const hasTrackedRef = useRef(false);
  const { data: plugins = {} } = useGetEndpointsQuery({
    enabled: !isSharedConvo,
    select: (data) => data?.gptPlugins?.plugins,
  });

  const getPluginName = useCallback(
    (pluginKey: string) => {
      if (!pluginKey) {
        return null;
      }

      if (pluginKey === 'n/a' || pluginKey === 'self reflection') {
        return pluginKey;
      }

      // Return the plugin key directly if it's not found in the plugins object
      return plugins[pluginKey] ?? pluginKey;
    },
    [plugins],
  );

  // Track tool usage once when plugin completes successfully
  useEffect(() => {
    if (
      !hasTrackedRef.current && // Ensure we haven't tracked this instance yet
      !plugin.loading && // Plugin has finished loading
      plugin.outputs && // Has outputs array
      plugin.outputs.length > 0 && // Has output (successful completion)
      plugin.latest && // Has a tool name
      plugin.latest !== 'n/a' && // Not a non-applicable case
      plugin.latest !== 'self reflection' // Not self reflection
    ) {
      trackToolUsage({
        tool_type: plugin.latest,
        success: true,
      });
      hasTrackedRef.current = true; // Mark as tracked
    }
  }, [plugin]);

  if (!plugin || !plugin.latest) {
    return null;
  }

  const latestPlugin = getPluginName(plugin.latest);

  if (!latestPlugin || latestPlugin === 'n/a') {
    return null;
  }

  const generateStatus = (): ReactNode => {
    if (!plugin.loading && latestPlugin === 'self reflection') {
      return 'Finished';
    } else if (latestPlugin === 'self reflection') {
      return 'I\'m  thinking...';
    } else {
      // Format the tool name to add spaces before capital letters
      const formattedName = formatToolName(latestPlugin);
      return (
        <>
          {plugin.loading ? 'Using' : 'Used'} <b>{formattedName}</b>
          {plugin.loading ? '...' : ''}
        </>
      );
    }
  };

  return (
    <div className='my-2 flex flex-col items-start'>
      <Disclosure>
        {({ open }) => {
          const iconProps: PluginIconProps = {
            className: cn(open ? 'rotate-180 transform' : '', 'h-4 w-4'),
          };
          return (
            <>
              <div
                className={cn(
                  plugin.loading ? 'bg-green-100' : 'bg-gray-20',
                  'my-1 flex items-center rounded p-3 text-xs text-gray-800',
                )}
              >
                <div>
                  <div className='flex items-center gap-3'>
                    <div>{generateStatus()}</div>
                  </div>
                </div>
                {plugin.loading && <Spinner className='ml-1 text-[#0F172A]' />}
                <DisclosureButton className='ml-12 flex items-center gap-2'>
                  <ChevronDownIcon {...iconProps} />
                </DisclosureButton>
              </div>

              <DisclosurePanel className='mt-3 flex max-w-full flex-col gap-3'>
                <CodeBlock
                  lang={`REQUEST TO ${formatToolName(latestPlugin).toUpperCase()}`}
                  codeChildren={formatInputs(plugin.inputs ?? [])}
                  plugin={true}
                  classProp='max-h-[450px]'
                />
                {plugin.outputs && plugin.outputs.length > 0 && (
                  <CodeBlock
                    lang={`RESPONSE FROM ${formatToolName(latestPlugin).toUpperCase()}`}
                    codeChildren={formatJSON(plugin.outputs)}
                    plugin={true}
                    classProp='max-h-[450px]'
                  />
                )}
              </DisclosurePanel>
            </>
          );
        }}
      </Disclosure>
    </div>
  );
};

export default memo(Plugin);
